<input
  hidden
  #addAdditionalImg
  accept="image/*"
  type="file"
  multiple
  name="addAdditionalImg"
  onclick="value = null"
  (change)="addPhotos($event)"
/>
<button
  *akbCheck="['world.akebono.auto.auctioneer']"
  nz-button
  nzSize="large"
  [nzLoading]="isAddingPhotos"
  (click)="addAdditionalImg.click()"
>
  <i nz-icon nzType="camera" nzTheme="fill"></i>
</button>
