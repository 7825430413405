import { QueryHandlingService } from '@akebono/core';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryRef } from 'apollo-angular';
import { Observable, of, Subscription } from 'rxjs';
import { Lot } from 'src/app/modules/graphql/service/graphql-auto-main-service';
import {
  LotsListFiltersQuery,
  LotsListFiltersQueryVariables,
  LotsListGQL,
  LotsListQuery,
  LotsListQueryVariables,
} from 'src/app/modules/graphql/service/graphql-cars-default-service';
import { LotListDataService } from 'src/app/modules/shared-components/lot-list-data-service/lot-list-data.service';

import { DateRange, getTypeSort, toNumber } from '../../../../const';
import { LotTypeEnum } from '../../../graphql/service/graphql-auto-main-service';
import { LotSource } from '../../../graphql/service/graphql-cars-default-service';

@Component({
  selector: 'app-auto-lots-list',
  templateUrl: './auto-lots-list.component.html',
  styleUrls: ['./auto-lots-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListComponent implements OnInit, OnDestroy {
  data: any = {
    total: 0,
    nodes: [],
  };
  filtersData: any;
  pageSize = 20;
  page = 1;
  loadingResult = true;
  loadingFilters = true;
  total = 0;

  years: number[] = [];
  minYear = 1985;

  form: FormGroup;

  companies: any;
  japanCompaniesList: any[];
  otherCompaniesList: any[];
  mainCompaniesList: any[];
  mainCompaniesNames = ['toyota', 'nissan', 'honda'];
  auctRefOptions: any[];
  trucks: any[];
  currentCompany;
  isYearFiltered = false;
  isMileageFiltered = false;
  isEngineVolumeFiltered = false;
  isProductionDateFiltered = false;
  isActiveFilters = true;

  inclusionFilterFields = {
    frame: 'modelTypeEn',
    score: 'scoresEn',
    auction: 'auctRef',
    color: 'colorEn',
    transmission: 'transmissionEn',
    date: 'date',
    result: 'result',
  };
  lastDay: Date;
  dateRange: DateRange[] = [];
  selectedDays: string[] = [];
  dumpVersion: string;
  outdated = false;
  lotsListQueryRef: QueryRef<LotsListQuery, LotsListQueryVariables>;
  lotsListFiltersQueryRef: QueryRef<LotsListFiltersQuery, LotsListFiltersQueryVariables>;
  sub = new Subscription();

  lotListData$: Observable<Map<string, Lot>> = of(new Map());

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private qhs: QueryHandlingService,
    private lotListDataService: LotListDataService,
    private listLotsGQL: LotsListGQL,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.loadingResult = true;
      this.page = toNumber(params.page) || 1;
      this.pageSize = toNumber(params.pageSize) || this.pageSize;
      const filter = this.getQueryFilter(params);
      const sort = {};
      if (params.sort && params.sortType) {
        sort[params.sort] = {
          direction: params.sortType,
        };
      }
      const queryFilters = {
        companyId: params.company || 0,
        modelId: params.model || 0,
        withCompany: typeof params.company !== 'undefined',
        withModel: typeof params.model !== 'undefined',
        first: this.pageSize,
        offset: (this.page - 1) * this.pageSize,
        filter,
        sort,
      };
      if (!this.lotsListQueryRef) {
        this.lotsListQueryRef = this.listLotsGQL.watch(queryFilters, {
          fetchPolicy: 'network-only',
        });
        this.lotsListQueryRef.valueChanges.subscribe(
          (result) => {
            this.loadingResult = false;
            this.data = result.data.lots;
            this.total = result.data.autoLots.total;
            this.lotListData$ = this.lotListDataService.fetchData(
              result.data.lots.nodes,
              LotTypeEnum.Auto,
              LotSource.Aleado,
            );
            this.checkOutdated(result.data.dumpVersion);
          },
          (error) => {
            console.log(error);
            this.loadingResult = false;
          },
        );
      } else {
        this.lotsListQueryRef.refetch(queryFilters).finally(() => {
          this.loadingResult = false;
        });
      }
    });
  }

  ngOnInit() {
    this.title.setTitle('ECar JP Lots');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private checkOutdated(dumpVersion) {
    if (!this.dumpVersion) {
      this.dumpVersion = dumpVersion;
    } else if (this.dumpVersion !== dumpVersion) {
      this.outdated = true;
    }
  }

  scrollToTable() {
    document.querySelector('.table').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  getQueryFilter(params: any): LotsListQueryVariables['filter'] {
    const inclusionFilter = [];
    Object.keys(this.inclusionFilterFields).forEach((key) => {
      if (params[key] && params[key].length > 0) {
        inclusionFilter.push({
          fieldName: this.inclusionFilterFields[key],
          eq: [].concat(params[key]),
        });
      }
    });
    const mFrom = Number(params.mileageFrom);
    const mTo = Number(params.mileageTo);
    return {
      companyRef: {
        eq: params.company,
      },
      modelNameRef: {
        eq: params.model,
      },
      truckRef: {
        eq: params.truck,
      },
      year: {
        gte: Number(params.yearFrom),
        lte: Number(params.yearTo),
      },
      mileage: {
        gte: mFrom ? mFrom : null,
        lte: mTo ? mTo : null,
      },
      engineVolume: {
        gte: Number(params.engineVolumeFrom),
        lte: Number(params.engineVolumeTo),
      },
      productionDate: {
        gte: params.productionDateFrom,
        lte: params.productionDateTo,
      },
      bid: {
        eq: params.bid,
      },
      inclusion:
        inclusionFilter.length > 0
          ? {
              columns: inclusionFilter,
            }
          : undefined,
      datetime: {
        gt: params.datetime,
      },
      modelGradeEn: {
        like: params.modelGrade,
      },
      leftDrive: {
        eq: params.drive ? params.drive == 'left' : undefined,
      },
    };
  }

  loadData(page) {
    this.router.navigate(['auto'], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(pageSize) {
    this.loadingResult = true;

    this.router.navigate(['auto'], {
      queryParams: {
        pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }

  onBack() {
    this.router.navigateByUrl('');
  }

  sortEvent(sort: { key: string; value: string }): void {
    this.loadingResult = true;

    this.router.navigate(['auto'], {
      queryParams: {
        sort: sort.key,
        sortType: getTypeSort(sort.value),
      },
      queryParamsHandling: 'merge',
    });
  }

  getDate(string = null): Date {
    if (string) {
      return new Date(string);
    }
    return new Date();
  }

  getPrice(price: number) {
    return price;
  }
}
