import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lot-notations-button',
  templateUrl: './lot-notations-button.component.html',
  styleUrls: ['./lot-notations-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotNotationsButtonComponent {
  get link(): string {
    return `https://${environment.domain}/article/sheet`;
  }
}
