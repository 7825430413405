import { Component, Input } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AutoLotView } from '../../../const';
import { LotSource, LotTypeEnum } from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-translate-button',
  templateUrl: './lot-translate-button.component.html',
  styleUrls: ['./lot-translate-button.component.scss'],
})
export class LotTranslateButtonComponent {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() windowHeight = 800;
  @Input() windowWidth = 950;

  open(): void {
    const features = `Toolbar=0,Location=0,Directories=0,Status=0,Menubar=0,Scrollbars=0,Width=${this.windowWidth},Height=${this.windowWidth}`;
    const url = new URL(environment.managerDomain);

    url.pathname = '/akebono-translator/lot-translate';
    url.searchParams.append('layout', 'clean');
    url.searchParams.append('lotId', this.lot.id);
    url.searchParams.append('lotType', this.lotType);
    url.searchParams.append('lotSource', this.lotSource);

    window.open(url.toString(), null, features);
  }
}
