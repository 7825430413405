import { QueryHandlingService } from '@akebono/core';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { combineLatest, Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, map, mapTo, pluck, startWith } from 'rxjs/operators';

import { AutoLotView, normalizeAuctionStartDatetime } from '../const';
import {
  Lot,
  UserLimitsDataGQL,
  UserLimitsDataQuery,
} from '../modules/graphql/service/graphql-auto-main-service';
import { BiddingRulesService } from './bidding-rules.service';

export type Limits = {
  loading$: Observable<boolean>;
  reached$: Observable<boolean>;
  timeLimitReached$: Observable<boolean>;
  lotsLimitReached$: Observable<boolean>;
  lotBidsLimitReached$: Observable<boolean>;
  canPalaceOnlyOneBid$: Observable<boolean>;
  productionDateCheckRequestLimitReached$: Observable<boolean>;
};

@Injectable()
export class LimitsService {
  constructor(
    private qhs: QueryHandlingService,
    private brs: BiddingRulesService,
    private userLimitsDataGQL: UserLimitsDataGQL,
  ) {}

  public getLimits(lot: AutoLotView, userLot: Lot | null): Limits {
    const userLimitsData = this.qhs.fetch(
      this.userLimitsDataGQL,
      { auctionDate: lot.date },
      'network-only',
    );

    const timeLimitReached$ = this.checkTimeLimitReached(userLimitsData.data, userLot, lot);
    const lotBidsLimitReached$ = this.checkLotBidsLimitReached(userLimitsData.data, userLot);
    const lotsLimitReached$ = this.checkLotsLimitReached(
      userLimitsData.data,
      userLot,
      lotBidsLimitReached$,
    );

    return {
      loading$: userLimitsData.loading,
      timeLimitReached$,
      lotBidsLimitReached$,
      lotsLimitReached$,
      reached$: combineLatest([timeLimitReached$, lotBidsLimitReached$, lotsLimitReached$]).pipe(
        map((limits) => limits.some((res) => res)),
        distinctUntilChanged(),
        startWith(true),
      ),
      canPalaceOnlyOneBid$: this.isCanPlaceOnlyOneBid(userLot),
      productionDateCheckRequestLimitReached$: userLimitsData.data.pipe(
        map((data) => data.currentUser?.lotProductionDateCheckRequestsLimitReached ?? false),
      ),
    };
  }

  private isCanPlaceOnlyOneBid(userLot: Lot | null): Observable<boolean> {
    if (userLot?.bids?.length === 0) {
      const auctionStart = normalizeAuctionStartDatetime(userLot.auctionDatetime);
      return timer(0, 1000).pipe(
        map(() => auctionStart.diffNow('minutes').minutes),
        map((minutesBeforeStart) => minutesBeforeStart > 0 && minutesBeforeStart < 60),
      );
    } else {
      return of(false);
    }
  }

  private checkLotsLimitReached(
    userLimitsData: Observable<UserLimitsDataQuery>,
    userLot: Lot | null,
    lotBidsLimitReached$: Observable<boolean>,
  ): Observable<boolean> {
    return combineLatest([
      userLimitsData.pipe(
        pluck('currentUser', 'lotsLimitReached'),
        map((v) => Boolean(v)),
      ),
      of(userLot).pipe(
        pluck('currentUserLastBid'),
        map((bid) => Boolean(bid)),
      ),
      lotBidsLimitReached$,
    ]).pipe(
      map(
        ([lotsLimitReached, hasBid, lotBidsLimitReached]) =>
          lotsLimitReached && (!hasBid || lotBidsLimitReached),
      ),
    );
  }

  private checkLotBidsLimitReached(
    userLimitsData: Observable<UserLimitsDataQuery>,
    userLot: Lot | null,
  ): Observable<boolean> {
    return userLimitsData.pipe(mapTo(Boolean(userLot?.currentUserLotBidsLimitReached)));
  }

  private checkTimeLimitReached(
    userLimitsData: Observable<UserLimitsDataQuery>,
    userLot: Lot | null,
    lot: AutoLotView,
  ): Observable<boolean> {
    return combineLatest([
      timer(0, 1000),
      userLimitsData.pipe(pluck('currentUser', 'permissions')),
      this.checkIfTenderAuctionTimeLimitReached(lot),
    ]).pipe(
      map(([_, permissions, isTenderTimeout]) => {
        const canOvertime = !!permissions.find((p) => p.code === 'cars.overtime');
        const auctionStart = normalizeAuctionStartDatetime(lot.time);

        if (canOvertime) {
          console.log('can overtime');
          return false;
        } else if (isTenderTimeout) {
          console.log('tender timeout');
          return true;
        } else if (userLot?.bidsCount > 0 && !userLot?.currentUserLastBid) {
          console.log(
            'more than hour to trade start',
            DateTime.now() >= auctionStart.minus({ minutes: 60 }),
          );
          return DateTime.now() >= auctionStart.minus({ minutes: 60 });
        } else {
          // console.log(
          //   'more than five minutes to trade',
          //   DateTime.now() >= auctionStart.minus({ minutes: 5 }),
          // );
          return DateTime.now() >= auctionStart.minus({ minutes: 15 });
        }
      }),
      distinctUntilChanged(),
    );
  }

  // Old logic based on UserHasHappyTime
  // private oldCheckTimeLimitReached(
  //   userLimitsData: Observable<UserLimitsDataQuery>,
  //   userLot: Lot | null,
  //   lot: AutoLotView,
  // ): Observable<boolean> {
  //   return combineLatest([
  //     timer(0, 1000),
  //     userLimitsData.pipe(pluck('currentUser', 'permissions')),
  //     this.checkIfTenderAuctionTimeLimitReached(lot),
  //   ]).pipe(
  //     map(([_, permissions, isTenderTimeout]) => {
  //       const canOvertime = !!permissions.find((p) => p.code === 'cars.overtime');
  //       const auctionStart = normalizeAuctionStartDatetime(lot.time);

  //       if (canOvertime) {
  //         return false;
  //       } else if (isTenderTimeout) {
  //         return true;
  //       } else if (DateTime.now() >= auctionStart.plus({ hours: 2 })) {
  //         return false;
  //       } else if (userLot?.currentUserHasHappyTime) {
  //         return DateTime.now() >= auctionStart.minus({ minutes: 15 });
  //       } else if (!userLot?.currentUserLastBid) {
  //         return DateTime.now() >= auctionStart.minus({ minutes: 60 });
  //       } else {
  //         return DateTime.now() >= auctionStart.minus({ minutes: 30 });
  //       }
  //     }),
  //     distinctUntilChanged(),
  //   );
  // }

  private checkIfTenderAuctionTimeLimitReached(lot: AutoLotView): Observable<boolean> {
    return this.brs.checkIfCanBidWithGroupsToAuction(lot.auctionName).pipe(
      map((isNotTender) => {
        const timeoutAt = DateTime.fromISO(lot.time, { zone: 'Asia/Tokyo' })
          .startOf('day')
          .plus({ hours: 13, minutes: 30 });

        return !isNotTender && DateTime.now() > timeoutAt;
      }),
    );
  }

  public checkOneHourBeforeAuctionReached(date: AutoLotView['time']): boolean {
    if (!date) return true;

    const auctionStart = normalizeAuctionStartDatetime(date);
    return DateTime.now() >= auctionStart.minus({ hour: 1 });
  }

  public check1100BidDeleteBorder(auctionStart: DateTime): boolean {
    return auctionStart.weekday === 6 && auctionStart.hour >= 11;
  }

  public check1700BidDeleteBorder(auctionStart: DateTime): boolean {
    return auctionStart.weekday !== 6 && auctionStart.hour >= 17;
  }
}
