<div class="content">
  <nz-collapse [nzBordered]="false">
    <nz-collapse-panel [nzHeader]="'FILTERS' | translate" [nzActive]="isActiveFilters">
      <div *ngIf="outdated" style="color: red">{{ 'FILTER_DATA_OUTDATED' | translate }}</div>
      <nz-skeleton *ngIf="loadingFilters" [nzActive]="true"></nz-skeleton>
      <form
        nz-form
        [formGroup]="form"
        (ngSubmit)="filter(form.value)"
        *ngIf="!loadingFilters"
        [nzLayout]="'vertical'"
      >
        <nz-row [nzGutter]="40">
          <nz-col [nzXs]="24" [nzMd]="8" [nzXl]="6">
            <div style="margin-bottom: 10px">
              <nz-tag
                *ngFor="let date of dateRange"
                nzMode="checkable"
                [nzChecked]="date.checked"
                (nzCheckedChange)="selectDateAuction($event, date)"
              >
                {{ date.date | date: 'EEE' | translate }}
              </nz-tag>
            </div>
            <nz-form-item>
              <nz-form-label
                ><strong>{{ 'COMPANY_MODEL' | translate }}</strong></nz-form-label
              >
              <nz-form-control>
                <nz-row [nzGutter]="20">
                  <nz-col [nzSpan]="12">
                    <nz-select
                      formControlName="company"
                      nzShowSearch
                      nzAllowClear
                      (nzOnSearch)="search($event)"
                      nzPlaceHolder="{{ 'SELECT_COMPANY' | translate }}"
                      (ngModelChange)="change($event)"
                    >
                      <nz-option nzValue="any" nzLabel="{{ 'ANY' | translate }}"></nz-option>
                      <nz-option
                        *ngFor="let item of mainCompaniesList"
                        [nzValue]="item.companyId"
                        [nzLabel]="item.name"
                      ></nz-option>
                      <nz-option-group nzLabel="JAPAN">
                        <nz-option
                          *ngFor="let item of japanCompaniesList"
                          [nzValue]="item.companyId"
                          [nzLabel]="item.name"
                        ></nz-option>
                      </nz-option-group>
                      <nz-option-group nzLabel="OTHER">
                        <nz-option
                          *ngFor="let item of otherCompaniesList"
                          [nzValue]="item.companyId"
                          [nzLabel]="item.name"
                        ></nz-option>
                      </nz-option-group>
                    </nz-select>
                  </nz-col>
                  <nz-col [nzSpan]="12">
                    <nz-select
                      *ngIf="form.controls.company.value"
                      formControlName="model"
                      nzShowSearch
                      nzAllowClear
                      (nzOnSearch)="search($event)"
                      (ngModelChange)="change($event)"
                    >
                      <nz-option nzValue="any" nzLabel="{{ 'ANY' | translate }}"></nz-option>
                      <nz-option
                        *ngFor="let m of companies[form.controls.company.value]?.autoModels"
                        [nzValue]="m.modelId"
                        nzLabel="{{ m.name }} ({{ m.autoLotsTotal }})"
                      ></nz-option>
                    </nz-select>
                  </nz-col>
                </nz-row>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label
                ><strong>{{ 'LOT_INFO.AUCTION' | translate }}</strong></nz-form-label
              >
              <nz-form-control>
                <nz-select
                  formControlName="inclusionAuction"
                  nzMode="multiple"
                  nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
                  [nzDisabled]="!filtersData.auctRefOptions"
                >
                  <nz-option
                    *ngFor="let option of auctRefOptions"
                    [nzLabel]="option.name"
                    [nzValue]="option.id"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label
                ><strong>{{ 'FRAME_MODEL' | translate }}</strong></nz-form-label
              >
              <nz-form-control>
                <nz-select
                  formControlName="inclusionFrame"
                  nzMode="multiple"
                  nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
                  [nzDisabled]="!filtersData.modelTypeEnOptions"
                >
                  <nz-option
                    *ngFor="let option of filtersData.modelTypeEnOptions"
                    nzLabel="{{ option.value }} ({{ option.count }})"
                    [nzValue]="option.value"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="datetime">
                  <strong>{{ 'AUCTION_NOT_STARTED' | translate }}</strong>
                </label>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col [nzXs]="24" [nzMd]="8" [nzXl]="6">
            <nz-form-item>
              <nz-form-label class="label-flex"
                ><strong>{{ 'LOT_INFO.YEAR' | translate }}</strong>
                <span
                  *ngIf="isYearFiltered"
                  (click)="removeFilter('year')"
                  nz-text
                  nzType="secondary"
                  >{{ 'SEE_ALL' | translate }}</span
                >
              </nz-form-label>
              <nz-form-control>
                <nz-input-group nzCompact nzSize="small" class="year-selectors">
                  <nz-select
                    formControlName="yearFrom"
                    class="from"
                    [nzShowArrow]="false"
                    nzSize="small"
                    (ngModelChange)="change($event)"
                    nzPlaceHolder="{{ 'FROM' | translate }}"
                    [nzDropdownMatchSelectWidth]="false"
                    style="width: calc(50% - 15px); text-align: center"
                  >
                    <nz-option
                      *ngFor="let year of years"
                      [nzValue]="year"
                      [nzLabel]="year"
                    ></nz-option>
                  </nz-select>
                  <input
                    type="text"
                    disabled
                    nz-input
                    placeholder="-"
                    style="
                      width: 30px;
                      border-left: 0px;
                      pointer-events: none;
                      background-color: rgb(255, 255, 255);
                    "
                  />
                  <nz-select
                    formControlName="yearTo"
                    class="to"
                    [nzDropdownMatchSelectWidth]="false"
                    [nzShowArrow]="false"
                    nzSize="small"
                    (ngModelChange)="change($event)"
                    nzPlaceHolder="{{ 'TO' | translate }}"
                    style="width: calc(50% - 15px); text-align: center; border-left: 0px"
                  >
                    <nz-option
                      *ngFor="let year of years"
                      [nzValue]="year"
                      [nzLabel]="year"
                    ></nz-option>
                  </nz-select>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label
                ><strong>{{ 'LOT_INFO.MILEAGE' | translate }}</strong>
                <span
                  *ngIf="isMileageFiltered"
                  (click)="removeFilter('mileage')"
                  nz-text
                  nzType="secondary"
                  >{{ 'SEE_ALL' | translate }}</span
                >
              </nz-form-label>
              <nz-form-control>
                <nz-input-group nzCompact nzSize="small">
                  <input
                    type="number"
                    nz-input
                    formControlName="mileageFrom"
                    (change)="change($event)"
                    placeholder="{{ 'FROM' | translate }}"
                    style="width: calc(50% - 15px); text-align: center"
                  />
                  <input
                    type="text"
                    disabled
                    nz-input
                    placeholder="-"
                    style="
                      width: 30px;
                      border-left: 0px;
                      pointer-events: none;
                      background-color: rgb(255, 255, 255);
                    "
                  />
                  <input
                    type="number"
                    nz-input
                    placeholder="{{ 'TO' | translate }}"
                    formControlName="mileageTo"
                    (change)="change($event)"
                    style="width: calc(50% - 15px); text-align: center; border-left: 0px"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label>
                <strong>{{ 'ENGINE_VOLUME' | translate }}</strong>
                <span
                  *ngIf="isEngineVolumeFiltered"
                  (click)="removeFilter('engineVolume')"
                  nz-text
                  nzType="secondary"
                  >{{ 'SEE_ALL' | translate }}</span
                >
              </nz-form-label>
              <nz-form-control>
                <nz-input-group nzCompact nzSize="small">
                  <input
                    type="number"
                    nz-input
                    formControlName="engineVolumeFrom"
                    (change)="change($event)"
                    placeholder="{{ 'FROM' | translate }}"
                    style="width: calc(50% - 15px); text-align: center"
                  />
                  <input
                    type="text"
                    disabled
                    nz-input
                    placeholder="-"
                    style="
                      width: 30px;
                      border-left: 0px;
                      pointer-events: none;
                      background-color: rgb(255, 255, 255);
                    "
                  />
                  <input
                    type="number"
                    nz-input
                    placeholder="{{ 'TO' | translate }}"
                    formControlName="engineVolumeTo"
                    (change)="change($event)"
                    style="width: calc(50% - 15px); text-align: center; border-left: 0px"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label>
                <strong>{{ 'PRODUCTION_DATE' | translate }}</strong>
                <span
                  *ngIf="isProductionDateFiltered"
                  (click)="removeFilter('productionDate')"
                  nz-text
                  nzType="secondary"
                  >{{ 'SEE_ALL' | translate }}</span
                >
              </nz-form-label>
              <nz-form-control>
                <nz-row>
                  <nz-input-group nzCompact nzSize="small">
                    <nz-range-picker
                      style="display: flex; text-align: center"
                      nzSeparator="-"
                      [nzPlaceHolder]="['FROM' | translate, 'TO' | translate]"
                      nzSize="small"
                      formControlName="productionDate"
                    ></nz-range-picker>
                  </nz-input-group>
                </nz-row>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col [nzXs]="24" [nzMd]="8" [nzXl]="6">
            <nz-form-item>
              <nz-form-label
                ><strong>{{ 'SEARCH_BY_BID' | translate }}</strong></nz-form-label
              >
              <nz-form-control>
                <input
                  type="text"
                  nzSize="small"
                  formControlName="bid"
                  nz-input
                  placeholder="{{ 'INPUT_BID_NUMBER' | translate }}"
                />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label
                ><strong>{{ 'LOT_INFO.SCORE' | translate }}</strong></nz-form-label
              >
              <nz-form-control>
                <nz-select
                  formControlName="inclusionScore"
                  nzSize="small"
                  nzMode="multiple"
                  nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
                  [nzDisabled]="!filtersData.scoresEnOptions"
                >
                  <nz-option
                    *ngFor="let option of filtersData.scoresEnOptions"
                    [nzLabel]="option.scoresEn"
                    [nzValue]="option.scoresEn"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label
                ><strong>{{ 'LOT_INFO.COLOR' | translate }}</strong></nz-form-label
              >
              <nz-form-control>
                <nz-select
                  formControlName="inclusionColor"
                  nzSize="small"
                  nzMode="multiple"
                  nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
                  [nzDisabled]="!filtersData.colorEnOptions"
                >
                  <nz-option
                    *ngFor="let option of filtersData.colorEnOptions"
                    [nzLabel]="option.colorEn"
                    [nzValue]="option.colorEn"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <!-- <nz-form-item>
              <nz-form-label><strong>{{'STATUS' | translate}}</strong></nz-form-label>
              <nz-form-control>
                <nz-select formControlName="inclusionStatus" nzSize="small" nzMode="multiple"
                  nzPlaceHolder="{{'PLEASE_SELECT' | translate}}" [nzDisabled]="!data.resultRefOptions">
                  <nz-option *ngFor="let option of data.resultRefOptions" [nzLabel]="option.result"
                    [nzValue]="option.result"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item> -->
            <nz-form-item>
              <nz-form-label
                ><strong>{{ 'TRANSMISSION' | translate }}</strong
                >{{ '' | translate }}</nz-form-label
              >
              <nz-form-control>
                <nz-select
                  formControlName="inclusionTransmission"
                  nzSize="small"
                  nzMode="multiple"
                  nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
                  [nzDisabled]="!filtersData.transmissionEnOptions"
                >
                  <nz-option
                    *ngFor="let option of filtersData.transmissionEnOptions"
                    [nzLabel]="option.transmissionEn"
                    [nzValue]="option.transmissionEn"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <nz-col [nzXs]="24" [nzMd]="8" [nzXl]="6">
            <nz-form-item>
              <nz-form-label>
                <strong>{{ 'LOT_INFO.TRUCK' | translate }}</strong>
              </nz-form-label>
              <nz-form-control>
                <nz-select
                  formControlName="truck"
                  nzMode="multiple"
                  nzSize="small"
                  nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
                >
                  <nz-option
                    *ngFor="let option of trucks"
                    [nzLabel]="option.name"
                    [nzValue]="option.id"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
        </nz-row>
        <div nz-row>
          <div nz-col [nzSpan]="24" class="search-area">
            <button nz-button nzType="primary" (click)="scrollToTable()">
              {{ 'SEARCH' | translate }}
            </button>
            <button nz-button (click)="resetFilter()">{{ 'CLEAR' | translate }}</button>
          </div>
        </div>
      </form>
    </nz-collapse-panel>
  </nz-collapse>
  <nz-divider></nz-divider>
  <div class="purchase-statistics">
    <app-user-purchase-statistics></app-user-purchase-statistics>
  </div>
  <nz-table
    #table
    nzTitle="{{ 'TOTAL' | translate }}: {{ data.total }}"
    [nzTotal]="data.total"
    [nzData]="data.nodes"
    [nzPageIndex]="page"
    [nzPageSize]="pageSize"
    [nzLoading]="loadingResult"
    [nzFrontPagination]="false"
    [nzBordered]="true"
    nzPaginationPosition="both"
    nzShowSizeChanger
    class="table"
    nzSize="small"
    (nzPageIndexChange)="loadData($event)"
    (nzPageSizeChange)="changePageSize($event)"
    nzOuterBordered
  >
    <thead>
      <tr>
        <th rowspan="2" nzAlign="center">{{ 'PHOTO' | translate }}</th>
        <th rowspan="2" nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'bid'"
            [title]="'LOT' | translate"
          >
          </app-sort-component>
        </th>
        <th rowspan="1" nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'auctRef'"
            [title]="'LOT_INFO.AUCTION' | translate"
          ></app-sort-component>
        </th>
        <th rowspan="1" nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'modelYearEn'"
            [title]="'LOT_INFO.YEAR' | translate"
          >
          </app-sort-component>
        </th>
        <th rowspan="1" nzAlign="center" [ngClass]="'hide-column-small hide-column-medium'">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'displacementNum'"
            [title]="'LOT_INFO.ENGINE_CC' | translate"
          ></app-sort-component>
        </th>
        <th rowspan="1" nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'mileageNum'"
            [title]="'LOT_INFO.MILEAGE' | translate"
          ></app-sort-component>
        </th>
        <th rowspan="1" nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'startPriceNum'"
            [title]="'LOT_INFO.FIXED_PRICE' | translate"
          ></app-sort-component>
        </th>
      </tr>
      <tr>
        <th nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'date'"
            [title]="'DATE' | translate"
          >
          </app-sort-component>
        </th>
        <th nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'modelTypeEn'"
            [title]="'FRAME_MODEL' | translate"
          >
          </app-sort-component>
        </th>
        <th nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'colorEn'"
            [title]="'LOT_INFO.COLOR' | translate"
          >
          </app-sort-component>
        </th>
        <th nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'scoresEn'"
            [title]="'LOT_INFO.SCORE' | translate"
          >
          </app-sort-component>
        </th>
        <th nzAlign="center">
          <app-sort-component
            (nzSortChange)="sortEvent($event)"
            [sortKey]="'endPriceNum'"
            [title]="'LOT_INFO.SOLD_FOR' | translate"
          >
          </app-sort-component>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of table.data">
        <td nzAlign="center">
          <app-lot-mini-gallery [lot]="item"></app-lot-mini-gallery>
        </td>
        <td nzAlign="center">
          <app-lots-list-lot-link
            [lot]="item"
            [lotType]="'oneprice'"
            link="/oneprice/lot/{{ item.id }}"
            [lotData]="(countersMap | async)?.get(item.id)"
          ></app-lots-list-lot-link>
        </td>
        <td nzAlign="center">
          {{ item.autoAuction.name }} <br />
          {{ item.time | date: 'yyyy-MM-dd H:mm:ss zz':'+0900' }}
        </td>
        <td nzAlign="left" [ngClass]="'hide-column-small hide-column-medium'">
          <span style="color: #6db3eb">{{ item.companyEn }} {{ item.modelNameEn }}</span>
          <br />
          <span nz-text nzType="danger">{{ item.year }}</span> {{ item.frame }}
          <br />
          <span nz-text nzType="secondary">{{ item.modelGrade }}</span>
        </td>
        <td nzAlign="left">
          <span nz-text nzType="danger">{{ item.transmissionEn }}</span>
          {{ item.engineVolume }} cc<br />
          <span nz-text nzType="secondary">{{ item.colorEn }}</span>
          <!-- {{item.equip}} -->
        </td>
        <td nzAlign="right">
          {{ item.mileage }}000km
          <span nz-text nzType="warning">{{ item.rate }}</span>
        </td>
        <td nzAlign="right">
          {{ item.startPrice | currency: currentCurrency?.name:'symbol-narrow' }}
          <br />
          {{ item.finishPrice | currency: currentCurrency?.name:'symbol-narrow' }}
          <nz-tag *ngIf="item.result" style="margin: 0px">
            {{ item.result | translate }}
          </nz-tag>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>
