import { CalculatorLot, TypeEnum } from '@akebono/calculator';
import { FormControl, FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import {
  Bid,
  BidCondition,
  BidStatusEnum,
  CurrentUser,
  LotSource,
  LotTranslation,
  LotTypeEnum,
  TranslationMediaTypeEnum,
  UserLotQuery,
} from './modules/graphql/service/graphql-auto-main-service';
import { AutoLotQuery } from './modules/graphql/service/graphql-cars-default-service';

export const PERMISSION_BOUNDARY_LOTS = 'cars.boundary-lots';
export const PERMISSION_IGNORE_BOUNDARY_GREEN_CORNER = 'cars.ignore-boundary-green-corner';
export const PERMISSION_TRANSLATOR = 'world.akebono.auto.translator';
export const PERMISSION_AUCTIONEER = 'world.akebono.auto.auctioneer';
export const PERMISSION_CHECK_PRODUCTION_DATE = 'cars.production-date-check';
export const PERMISSION_RECHECK_PRODUCTION_DATE = 'cars.production-date-recheck';
export const PERMISSION_BEST_BID_ADMIN = 'cars.best-bid-admin';
export const PERMISSION_LOTS = 'cars.lots';
export const PERMISSION_ONEPRICE_LOTS = 'cars.oneprice-lots';
export const SIGN_URL = '/sign-in';
export const TITLE_MAIN_PAGE = 'ECar JP';
export const LETTERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'AA',
  'AB',
  'AC',
  'AD',
  'AE',
  'AF',
  'AG',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AN',
  'AO',
  'AP',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AV',
  'AW',
  'AX',
  'AY',
  'AZ',
  'BA',
  'BB',
  'BC',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BK',
  'BL',
  'BM',
  'BN',
  'BO',
  'BP',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BU',
  'BV',
  'BW',
  'BX',
  'BY',
  'BZ',
  'CA',
  'CB',
  'CC',
  'CD',
  'CE',
  'CF',
  'CG',
  'CH',
  'CI',
  'CJ',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CP',
  'CQ',
  'CR',
  'CS',
  'CT',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
];

export const BID_CONDITION_INTACT = 1;
export const BID_CONDITION_BOARD_CONSTRUCTOR = 15;
export const BID_CONDITION_BOARD_SAW_BACK = 8;
export const BID_CONDITION_PALLET = 23;
export const BID_CONDITION_OTHER_COUNTRIES = 24;
export const BID_CONDITION_UTILIZATION = 25;
export const BID_CONDITION_OTHER_COUNTRIES_MOTO = 28;

export function isOtherCountriesCondition(condition: BidCondition): boolean {
  return [BID_CONDITION_OTHER_COUNTRIES, BID_CONDITION_OTHER_COUNTRIES_MOTO].includes(
    Number(condition.id),
  );
}

export const bidConditionsOrderMap = {
  [BID_CONDITION_INTACT]: 1,
  [BID_CONDITION_BOARD_CONSTRUCTOR]: 2,
  [BID_CONDITION_BOARD_SAW_BACK]: 3,
};

export const PLAN_GREEN_CORNER = 17;

export enum AlertsCountry {
  Ru = 'ru',
  Another = 'another',
}

export interface DateRange {
  date: Date;
  checked: boolean;
}

export function toNumber(string): number {
  return Number(string);
}

export function simpleNameSort(a, b) {
  const name1 = a?.name?.toUpperCase();
  const name2 = b?.name?.toUpperCase();
  if (name1 === name2) {
    return 0;
  }
  return name1 > name2 ? 1 : -1;
}

export function getDaysRange(start: Date, endDate: Date) {
  let current = start;
  const arr = [];
  while (current < endDate) {
    arr.push(new Date(current));
    current = toDate(current.setDate(current.getDate() + 1));
  }
  return arr;
}

export function toDate(date) {
  return new Date(date);
}

export function toLocalIsoString(date) {
  const localIsoString =
    date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());
  return localIsoString;
}

export function validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
      control.updateValueAndValidity({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    }
  });
}

function pad(n) {
  return n < 10 ? '0' + n : n;
}

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const keysToCamel = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

export function getTypeSort(type: string): string | null {
  return type ? (type === 'ascend' ? 'asc' : 'desc') : null;
}

export type ProductionDatePayload = {
  epc_export?: {
    [key: string]: string;
  };
};

export type ReactiveLoadings = {
  [identifier: string]: Observable<boolean>;
};

export interface ObservableLoadings {
  [id: string]: Observable<boolean>;
}

export interface Pagination {
  page: number;
  pageSize: number;
  total: number;
}

export interface AutoLotView {
  id?: string;
  images: string[];
  bid: string;
  company?: string;
  companyId: number;
  modelName?: string;
  modelId?: number;
  auctionName: string;
  time: string;
  date: string;
  year?: string;
  frame?: string;
  modelGrade?: string;
  color?: string;
  transmission?: string;
  engineVolume?: number;
  equip?: string;
  mileage?: string;
  inspectionEn?: string;
  inspection?: string;
  rate?: string;
  startPrice?: number;
  finishPrice?: number;
  averagePrice?: number;
  parsedData?: any;
  parsedDataRu?: any;
  parsedDataEn?: any;
  dumpVersion?: string;
  result?: string;
  status?: string;
  vin?: string;
  productionDate?: string;
  productionDatePayload?: ProductionDatePayload;
  negotiationsPrice?: number;
  source?: LotSource;
  bidsCount?: number;
  viewsCount?: number;
  biddersCount?: number;
  inFavoritesCount?: number;
  isLotDataChanged?: boolean;
  expectedTranslationInSeconds?: number;
}

export interface ManualLotData {
  company: string;
  modelName: string;
  year: string;
  frame: string;
  lotType: LotTypeEnum;
}

export function mergeSavedLotToAutoLotView(
  lot: AutoLotView,
  savedLot: UserLotQuery['lot'],
): AutoLotView {
  const lotView = {
    ...lot,
    images: lot?.images?.length > savedLot?.images?.length ? lot?.images : savedLot?.images,
    bid: savedLot.code,
    company: savedLot.companyEn,
    modelName: savedLot.modelEn,
    auctionName: savedLot.auctionName,
    time: savedLot.auctionDatetime,
    date: savedLot.auctionDate,
    year: savedLot.year ?? savedLot.modelYearEn,
    frame: savedLot.modelTypeEn,
    vin: savedLot.vin || (savedLot.modelTypeEn ? savedLot.modelTypeEn + '-' : ''),
    modelGrade: savedLot.modelGradeEn,
    color: savedLot.colorEn,
    transmission: savedLot.transmissionEn,
    engineVolume: savedLot.engineVolumeNum,
    equip: savedLot.equipmentEn,
    mileage: savedLot.mileageNum,
    rate: savedLot.ratingEn,
    status: savedLot.status,
    productionDate: savedLot.productionDate,
    productionDatePayload: savedLot.productionDatePayload,
    startPrice: Number(savedLot.startPriceNum),
    bidsCount: savedLot.bidsCount,
    biddersCount: savedLot.biddersCount,
    viewsCount: savedLot.viewsCount,
    inFavoritesCount: savedLot.inFavoritesCount,
    parsedData: savedLot.description ?? lot?.parsedData,
    finishPrice:
      Number(savedLot.endPriceNum) || Number(savedLot.soldPrice) || Number(lot?.finishPrice),
    isLotDataChanged: isLotDataChanged(lot, savedLot),
    expectedTranslationInSeconds: savedLot.expectedTranslationInSeconds,
  };
  lotView.images = lotView.images.map((url) =>
    url.replace('https://p3.aleado.com/', environment.imageProxy),
  );
  if (!lotView.id) {
    lotView.id = savedLot.id;
  }
  return lotView;
}

export function isLotDataChanged(lot: AutoLotView, savedLot: UserLotQuery['lot']): boolean {
  if (lot) {
    return (
      lot.company !== savedLot.companyEn ||
      lot.modelName !== savedLot.modelEn ||
      lot.rate !== savedLot.ratingEn
    );
  } else {
    return false;
  }
}

export function isRussianUser(user: CurrentUser): boolean {
  const country = user.countryIso?.toLowerCase();
  return (
    country === 'россия' ||
    country === 'russia' ||
    country === 'ru' ||
    country === 'rus' ||
    country === 'rf' ||
    country === 'рф' ||
    country === 'рашка'
  );
}

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export function getLotSourceFromParams(params: Params): LotSource {
  const paramsSource = params.source;
  const targetLotSource = Object.values(LotSource).find((source) => source === paramsSource);

  if (targetLotSource) {
    return targetLotSource;
  } else {
    switch (paramsSource) {
      case 'ext':
        return LotSource.Mikhail;
      default:
        return LotSource.Aleado;
    }
  }
}

export function isActiveLot(lot: AutoLotView | AutoLotQuery['autoLot']): boolean {
  return DateTime.now() < normalizeAuctionStartDatetime(lot.time);
}

export function normalizeAuctionStartDatetime(auctionDatetime: string): DateTime {
  const auctionStart = DateTime.fromISO(auctionDatetime, { zone: 'Asia/Tokyo' });

  if (auctionStart.hour === 0 && auctionStart.minute === 0 && auctionStart.second === 0) {
    return auctionStart.set({ hour: 23, minute: 59, second: 59 });
  } else {
    return auctionStart;
  }
}

export function getCalcData(lot: AutoLotView, amount?: number): CalculatorLot {
  return {
    year: Number(lot.year),
    maker: lot.company,
    model: lot.modelName,
    amount: String(amount || lot.startPrice || 0),
    auction: lot.auctionName,
    modelType: lot.frame,
    engineVolume: lot.engineVolume,
  };
}

export type LotData = {
  lotId?: string;
  lot?: AutoLotView;
  lotSource?: LotSource;
  lotType?: LotTypeEnum;
  fullVin?: boolean;
  currentUser?: UserLotQuery['currentUser'];
  translations?: UserLotQuery['lot']['translations'];
  currentUserLastBid?: UserLotQuery['lot']['currentUserLastBid'];
  calcData?: CalculatorLot;
  calcTypes?: TypeEnum[];
  isActiveLot?: boolean;
  isUsingSavedImages?: boolean;
  isBidsLimitForUserReached?: boolean;
  currentTime?: DateTime;
  hasTranslations?: boolean;
};

export type SharedLotData = LotData & {
  fullLotLink?: string;
  hasLotsPermission?: boolean;
};

export type ReactiveLotData = Observable<LotData>;
export type ReactiveSharedLotData = Observable<SharedLotData>;

export function getSharedLotLinkById(id: number): string {
  return `https://${environment.domain}/shared/${id}`;
}

export function hasCurrentLanguageTranslation(
  translations: LotTranslation[] | null,
  user: CurrentUser,
): boolean {
  const { defaultTranslationMediaType, preferredLang } = user;
  return Boolean(
    translations?.find((translation) => {
      if (defaultTranslationMediaType === TranslationMediaTypeEnum.Audio && !translation.file?.id) {
        return false;
      }
      if (defaultTranslationMediaType === TranslationMediaTypeEnum.Text && !translation.message) {
        return false;
      }
      return translation.language === preferredLang;
    }),
  );
}

export function getAvailableCalculatorTypes(
  lotType: LotTypeEnum,
  user: UserLotQuery['currentUser'],
): TypeEnum[] {
  const { countryIso, isOtherCountries, isAuctioneer } = user;
  const isMongolianUser = countryIso.toLowerCase() === 'mn';
  const calculatorTypesMap = {
    auctioneer: {
      [LotTypeEnum.Auto]: [TypeEnum.Automobile, TypeEnum.Parts, TypeEnum.MongoliaAuto],
      [LotTypeEnum.Moto]: [TypeEnum.Motorcycle],
      [LotTypeEnum.Oneprice]: [
        TypeEnum.Automobile,
        TypeEnum.ElectroAuto,
        TypeEnum.Parts,
        TypeEnum.Hybrid,
        TypeEnum.MongoliaAuto,
      ],
    },

    mongolian: {
      [LotTypeEnum.Auto]: [TypeEnum.MongoliaAuto],
    },

    russian: {
      [LotTypeEnum.Auto]: [TypeEnum.Automobile, TypeEnum.Parts],
      [LotTypeEnum.Moto]: [TypeEnum.Motorcycle],
      [LotTypeEnum.Oneprice]: [
        TypeEnum.Automobile,
        TypeEnum.ElectroAuto,
        TypeEnum.Parts,
        TypeEnum.Hybrid,
        TypeEnum.MongoliaAuto,
      ],
    },
  };

  if (isOtherCountries && !isMongolianUser && !isAuctioneer) {
    return [];
  }

  if (isAuctioneer) {
    return calculatorTypesMap.auctioneer[lotType];
  } else if (isMongolianUser) {
    return calculatorTypesMap.mongolian[lotType];
  } else {
    return calculatorTypesMap.russian[lotType];
  }
}

export function getBase64(img: File, callback: (img: string) => void): void {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result!.toString()));
  reader.readAsDataURL(img);
}

export function getImgPrefix(): string {
  return `https://img.${environment.domain}/`;
}

export const noSawCutStaging = [1, 5, 20, 24];
export const noSawCutProduction = [1, 5, 20, 24];

const electricModels = {};

export function mileageToInterval(mileageEn: string): string {
  const mileage = Number(mileageEn);
  if (mileage < 25) {
    return '1';
  } else if (mileage < 50) {
    return '2';
  } else if (mileage < 75) {
    return '3';
  } else if (mileage < 100) {
    return '4';
  } else if (mileage < 125) {
    return '5';
  } else if (mileage < 150) {
    return '6';
  } else if (mileage > 150) {
    return '7';
  } else {
    return 'totalAvg';
  }
}

export enum BoundaryStatus {
  NotBoundary = 'NOT_BOUNDARY',
  Boundary = 'BOUNDARY',
  Overdue = 'OVERDUE',
  Early = 'EARLY',
}

export const SPECIAL_EQUIPMENT_ID = '5';

export type GearboxType = 'automatic' | 'manual';

export const TEST_BID_ID = 'f261e29a-a7f1-456f-bf55-567f4d1d906d';
export const TEST_BID: Bid = {
  id: TEST_BID_ID,
  amount: 1,
  bidCondition: {
    title: 'Akebono Test',
  },
  bidGroup: {
    date: '2100-01-01',
    letter: 'C',
    quantity: '1',
    bidsLimitReached: false,
    lotsLimitReached: false,
  },
  lot: {
    title: 'MAZDA 3',
    images: ['/assets/mazda2.jpeg'],
    auctionDatetime: '2100-01-01T03:03:00Z',
    engineVolumeNum: 1,
    link: '#',
    code: '1',
  },
  status: BidStatusEnum.Confirmed,
};
