<nz-spin nzSize="large" [nzSpinning]="loading$ | async">
  <header>
    <app-lot-last-visit-date [lotId]="(data$ | async)?.lot?.id"></app-lot-last-visit-date>
    <nz-result
      *ngIf="(loading$ | async) === false && !(data$ | async)?.lot"
      nzStatus="404"
      [nzTitle]="'LOT_NOT_FOUND' | translate"
    ></nz-result>
    <app-lot-title-full *ngIf="(data$ | async)?.lot as lot" [lot]="lot"></app-lot-title-full>
  </header>

  <main *ngIf="data$ | async as data" nz-row nzGutter="16">
    <aside nz-col nzXs="24" nzLg="10">
      <section>
        <app-lot-carousel
          [lot]="data.lot"
          [lotType]="data.lotType"
          [lotSource]="data.lotSource"
          [isUsingSavedImages]="data.isUsingSavedImages"
          (imgDelete)="refetch$.next()"
        ></app-lot-carousel>
      </section>

      <section>
        <app-alerts-list-auto
          [target]="'lot_page'"
          [auction]="data.lot.auctionName"
          [lotCode]="data.lot.bid"
        ></app-alerts-list-auto>
      </section>
    </aside>

    <aside nz-col nzXs="24" nzLg="14">
      <section>
        <app-lot-icons
          [lot]="data.lot"
          [lotType]="data.lotType"
          [lotSource]="data.lotSource"
          [lotIcons]="data.icons"
          [currentUser]="data.currentUser"
        ></app-lot-icons>
      </section>

      <section>
        <div class="buttons">
          <app-lot-place-bid-button
            *ngIf="data.lot"
            [lot]="data.lot"
            [limits]="data.limits"
            [lotType]="data.lotType"
            [lotSource]="data.lotSource"
            [calculatorTypes]="data.calcTypes"
            [hasTranslations]="data.hasTranslations"
            [currentUserLimitsOverrideAvailable]="data.currentUserLimitsOverrideAvailable"
            [currentUserLastBid]="data.currentUserLastBid"
            (afterSubmit)="refetch$.next()"
          ></app-lot-place-bid-button>
          <app-share-button
            [lot]="data.lot"
            [lotType]="data.lotType"
            [lotSource]="data.lotSource"
            [userLotMarks]="data.currentUserLotMarks"
            (changeMark)="refetch$.next()"
          ></app-share-button>
          <app-lot-translate-button
            [lot]="data.lot"
            [lotType]="data.lotType"
            [lotSource]="data.lotSource"
          ></app-lot-translate-button>
          <app-lot-add-photo-button
            [lot]="data.lot"
            [lotType]="data.lotType"
            [lotSource]="data.lotSource"
            [currentUser]="data.currentUser"
            (finish)="refetch$.next()"
          ></app-lot-add-photo-button>
          <app-lot-bids
            *ngIf="data.currentUser.canSeeUsersBids === true"
            [bids]="data.bids"
          ></app-lot-bids>
          <app-lot-events-modal
            [lot]="data.savedLot"
            [currentUser]="data.currentUser"
          ></app-lot-events-modal>
          <app-lot-reset-button
            *ngIf="data.lot.isLotDataChanged"
            [lot]="data.lot"
            [lotType]="data.lotType"
            [lotSource]="data.lotSource"
            [currentUser]="data.currentUser"
            (resetCompleted)="refetch$.next()"
          ></app-lot-reset-button>
          <app-purchase-report-button
            *ngIf="data.currentUser.isAuctioneer"
            [lot]="data.lot"
            [lotType]="data.lotType"
          ></app-purchase-report-button>
        </div>

        <div class="buttons">
          <app-lot-create-translation-request-button
            [currentUser]="data.currentUser"
            [lot]="data.lot"
            [lotType]="data.lotType"
            [lotSource]="data.lotSource"
            [hasTranslations]="data.hasTranslations"
            (translationRequestCreated)="refetch$.next()"
            (clickIfTranslationsExists)="scrollToTranslations()"
          ></app-lot-create-translation-request-button>
        </div>
      </section>

      <section>
        <app-lot-shared-add-list-button
          [lot]="data.lot"
          [lotType]="data.lotType"
          [lotSource]="data.lotSource"
          [userLotMarks]="data.currentUserLotMarks"
        ></app-lot-shared-add-list-button>
      </section>

      <section>
        <app-lot-shared-includes [lotId]="data.lot?.id"></app-lot-shared-includes>
      </section>

      <section>
        <app-lot-translations
          id="lotTranslations"
          [translations]="data.translations"
        ></app-lot-translations>
      </section>

      <section>
        <app-lot-boundary-info
          [lot]="data.lot"
          [style]="'alert'"
          [excludeStatuses]="[BoundaryStatus.NotBoundary, BoundaryStatus.Early]"
        ></app-lot-boundary-info>
      </section>

      <section>
        <app-lot-main-info
          [lot]="data.lot"
          [lotType]="data.lotType"
          [lotSource]="data.lotSource"
          [bestBid]="data.bestBid"
          [hasBestBid]="data.hasBestBid"
          [currentUser]="data.currentUser"
          [hasTranslations]="data.hasTranslations"
          [currentUserLastBid]="data.currentUserLastBid"
          (actionComplete)="refetch$.next()"
        ></app-lot-main-info>
      </section>

      <section *ngIf="!data.currentUser.isOtherCountries">
        <lib-akebono-calculator
          [lot]="data.calcData"
          [types]="data.calcTypes"
        ></lib-akebono-calculator>
      </section>
    </aside>
  </main>
</nz-spin>
