import { TypeEnum } from '@akebono/calculator';
import {
  MutationHandlingServiceV2,
  NotificationService,
  ObservableHandlingService,
  ObservableRef,
  QueryHandlingService,
  WatchQueryRef,
} from '@akebono/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Config, DriveStep } from 'driver.js';
import { DateTime } from 'luxon';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { combineLatest, iif, interval, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  pluck,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { isHybridOrElectric } from 'src/app/hybrids';

import {
  AutoLotView,
  BID_CONDITION_UTILIZATION,
  isOtherCountriesCondition,
  SPECIAL_EQUIPMENT_ID,
  validateAllFormFields,
} from '../../../const';
import { AuctionService } from '../../../services/auction.service';
import { BidConditionService } from '../../../services/bid-condition.service';
import { BiddingRulesService } from '../../../services/bidding-rules.service';
import { Limits, LimitsService } from '../../../services/limits.service';
import {
  AlertFragment,
  BidCondition,
  BidCreateGQL,
  BidCreateInput,
  BidDataGQL,
  BidDataQuery,
  BidGroup,
  BidGroupCreateGQL,
  CurrentUser,
  CurrentUserMinimalGQL,
  CurrentUserMinimalQuery,
  CurrentUserMinimalQueryVariables,
  LotSource,
  LotTypeEnum,
  PlaceBidHint,
  UserLotQuery,
} from '../../graphql/service/graphql-auto-main-service';
import { Auction } from '../../graphql/service/graphql-stats-auto-service';
import { AlertService } from '../alerts-list/alert.service';
import { DriverService } from '../driver/driver.service';
import { SettingsModalComponent } from '../settings-modal/components/settings-modal/settings-modal.component';
import { NavisRulesModalComponent } from './components/navis-rules-modal/navis-rules-modal.component';
import { PriorityPassInfoComponent } from './components/priority-pass-info/priority-pass-info.component';
import { SetBirthdateModalComponent } from './components/set-birthdate-modal/set-birthdate-modal.component';

type BidData = {
  conditions: BidCondition[];
  isCanGroupBids: boolean;
  bidGroups: BidGroup[];
  allowedLetters: string[];
  alerts: AlertFragment[];
  currentUser?: CurrentUser;
  maxBidAmount?: number;
  minBidCommission?: number;
  ignoreSmallBidWarning: boolean;
  auction: Auction;
  isSanctioned: boolean;
};

@Component({
  selector: 'app-lot-bidding-modal',
  templateUrl: './lot-bidding-modal.component.html',
  styleUrls: ['./lot-bidding-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotBiddingModalComponent implements OnInit, OnDestroy {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() limits: Limits;
  @Input() hasTranslations = false;
  @Input() withLimitsOverride = false;
  @Input() currentUserLastBid?: UserLotQuery['lot']['currentUserLastBid'];
  @Input() calculatorTypes: TypeEnum[] = [];
  @Input() renderCalculator = false;
  @Output() afterSubmit = new EventEmitter<boolean>();

  @ViewChild('groupBids') groupBidsRef: ElementRef;
  @ViewChild('delNewGroupRef') delNewGroupRef;

  biddingDataQueryRef: ObservableRef<BidData> = null;
  isPlacingBid$ = of(false);
  mayNotBeProcessed = DateTime.now().get('hour') >= 18;

  submitDisabled$ = of(true);
  maxBidLimitReached$ = new Subject<boolean>();
  canOrderPriorityPass$ = of(false);
  maxBidAmountLimitExceeded$ = of(false);
  bidDelete1700prohibited = false;
  bidDelete1100prohibited = false;

  placeConfirmationNotNeed$: Observable<boolean>;
  bidMakeSense$: Observable<boolean>;
  isHightImportDutyLot$: Observable<boolean>;
  isSanctionedUtilization$: Observable<boolean>;
  bidMakeSenseChange$ = new ReplaySubject<boolean>(1);

  isNewGroup = false;

  form: FormGroup;
  isDisassembly = false;

  SPECIAL_EQUIPMENT_ID = SPECIAL_EQUIPMENT_ID;

  formatterYen = (value: number) => `¥ ${value && value.toLocaleString('en-US')}`;
  parserYen = (value: string) => value.replace(/\D/g, '');

  queryRef: WatchQueryRef<CurrentUserMinimalQuery, CurrentUserMinimalQueryVariables>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    public modal: NzModalRef,
    private bcs: BidConditionService,
    private brs: BiddingRulesService,
    private translate: TranslateService,
    private qhs: QueryHandlingService,
    private mhs: MutationHandlingServiceV2,
    private ohs: ObservableHandlingService,
    private auctionService: AuctionService,
    private notification: NotificationService,
    private bidDataGQL: BidDataGQL,
    private bidCreateGQL: BidCreateGQL,
    private modalService: NzModalService,
    private bidGroupCreateGQL: BidGroupCreateGQL,
    private currentUserMinimalGQL: CurrentUserMinimalGQL,
    private limitService: LimitsService,
    private alertService: AlertService,
    private driverService: DriverService,
    private transService: TranslateService,
  ) {
    this.queryRef = this.qhs.watch(this.currentUserMinimalGQL);
  }

  ngOnInit(): void {
    this.createForm();
    this.bidDelete1700prohibited = this.limitService.check1700BidDeleteBorder(
      DateTime.fromISO(this.lot.time, { zone: 'Asia/Tokyo' }),
    );
    this.bidDelete1100prohibited = this.limitService.check1100BidDeleteBorder(
      DateTime.fromISO(this.lot.time, { zone: 'Asia/Tokyo' }),
    );

    const bidData$ = combineLatest([
      this.auctionService.getByName(this.lot.auctionName),
      this.brs.checkIfCanBidWithGroupsToAuction(this.lot.auctionName),
    ]).pipe(
      switchMap(([auction, isCanGroupBids]) =>
        this.qhs
          .fetch(
            this.bidDataGQL,
            {
              date: DateTime.fromISO(this.lot.time, { zone: 'Asia/Tokyo' }).startOf('day').toISO(),
              auctionId: auction?.id?.toString(),
              lotType: this.lotType,
            },
            'network-only',
          )
          .data.pipe(
            map((data) => [data, isCanGroupBids, auction] as [BidDataQuery, boolean, Auction]),
          ),
      ),

      switchMap(([data, isCanGroupBids, auction]) =>
        this.bcs
          .filterForLot(data.bidConditions, this.lot, this.lotType, data.currentUser)
          .pipe(
            map(
              (conditions) =>
                [data, conditions, isCanGroupBids, auction] as [
                  BidDataQuery,
                  BidCondition[],
                  boolean,
                  Auction,
                ],
            ),
          ),
      ),

      tap(([data, conditions]) => {
        const createTranslation =
          this.hasTranslations || this.limitService.checkOneHourBeforeAuctionReached(this.lot?.time)
            ? false
            : data.currentUser?.bidTranslationByDefault ?? false;
        const defaultCondition = this.bcs.getDefault(conditions, data.currentUser);
        this.resetForm(defaultCondition, createTranslation);
      }),

      map(
        ([data, conditions, isCanGroupBids, auction]) =>
          <BidData>{
            auction,
            isCanGroupBids,
            currentUser: data.currentUser,
            maxBidAmount: data.maxBidAmount,
            minBidCommission: data.minBidCommission,
            isSanctioned: this.isRUSanctioned(data.currentUser),
            conditions: this.bcs.sort(conditions),
            bidGroups: data.currentUser.bidGroups.nodes,
            ignoreSmallBidWarning: data.currentUser.ignoreSmallBidWarning,
            allowedLetters: this.brs.getAllowedLetters(data.currentUser),
            isToyamaUser: !data.currentUser.officeBranch.isWorldwide,
            alerts: this.alertService.filterByCountryIso(
              [
                ...data.alerts,
                ...(this.isRUSanctioned(data.currentUser) ? data.sanctionedAlerts : []),
              ],
              data.currentUser?.countryIso,
            ),
          },
      ),

      shareReplay(),
    );

    this.canOrderPriorityPass$ = bidData$.pipe(
      pluck('currentUser', 'isOtherCountries'),
      startWith(false),
    );

    this.maxBidAmountLimitExceeded$ = combineLatest([
      this.form.controls.amount.valueChanges,
      bidData$.pipe(pluck('currentUser', 'maxBidAmountLimit')),
    ]).pipe(
      map(([amount, maxAmount]) => {
        if (maxAmount === null) {
          return false;
        } else {
          return amount > maxAmount;
        }
      }),
    );

    this.canOrderPriorityPass$ = combineLatest([
      bidData$.pipe(pluck('currentUser', 'isOtherCountries')),
      this.form.controls.conditionId.valueChanges.pipe(
        map((conditionId) => isOtherCountriesCondition({ id: conditionId })),
        tap((isNotOtherCountriesBidCondition) => {
          if (!isNotOtherCountriesBidCondition) {
            this.form.controls.needPriorityPass.setValue(false);
          }
        }),
      ),
    ]).pipe(
      map(
        ([isNotOtherCountriesPlan, isNotOtherCountriesBidCondition]) =>
          isNotOtherCountriesPlan && isNotOtherCountriesBidCondition,
      ),
      startWith(false),
    );

    this.isHightImportDutyLot$ = combineLatest([bidData$, of(this.lot)]).pipe(
      map(([bidData, lot]) => {
        const { company, modelName, frame } = this.lot;
        const isRussianClient = bidData.currentUser?.countryIso?.toLowerCase() === 'ru';
        return (
          isRussianClient &&
          company.toLowerCase() === 'nissan' &&
          modelName.toLowerCase() === 'nv200' &&
          frame.toLowerCase() === 'vm20'
        );
      }),
    );

    this.submitDisabled$ = combineLatest([
      this.maxBidLimitReached$,
      this.limits.timeLimitReached$,
      this.limits.lotBidsLimitReached$,
      this.limits.lotsLimitReached$,
      this.form.controls.agreement.valueChanges,
      this.form.controls.commissionAgreement.valueChanges,
      this.maxBidAmountLimitExceeded$,
    ]).pipe(
      map(
        ([
          maxBidLimitReached,
          timeLimitReached,
          lotBidsLimitReached,
          bidsLimitReached,
          agreement,
          commissionAgreement,
          maxBinAmountLimitExceeded,
        ]) =>
          maxBinAmountLimitExceeded ||
          !agreement ||
          (maxBidLimitReached && !commissionAgreement) ||
          timeLimitReached ||
          ((lotBidsLimitReached || bidsLimitReached) && !this.withLimitsOverride),
      ),
      startWith(true),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.biddingDataQueryRef = this.ohs.toReference(bidData$);

    if (this.lot.time && this.mayNotBeProcessed) {
      const aucDate = DateTime.fromISO(this.lot.time);

      this.mayNotBeProcessed =
        aucDate > DateTime.now().startOf('day') && aucDate < DateTime.now().endOf('day');
    }

    this.bidMakeSense$ = combineLatest([
      this.bidMakeSenseChange$,
      this.biddingDataQueryRef.data.pipe(map((data) => data.ignoreSmallBidWarning)),
    ]).pipe(
      map(([bidMakeSene, ignoreSmallBidWarning]) => ignoreSmallBidWarning || bidMakeSene),
      startWith(true),
    );

    this.isSanctionedUtilization$ = combineLatest([
      bidData$.pipe(map((data) => data.isSanctioned)),
      this.form.controls.conditionId.valueChanges.pipe(
        map((conditionId) => Number(conditionId) === BID_CONDITION_UTILIZATION),
      ),
    ]).pipe(
      map(([isSanctionedLot, isUtilizationCondition]) => isSanctionedLot && isUtilizationCondition),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.placeConfirmationNotNeed$ = combineLatest([
      this.bidMakeSense$,
      this.limits.canPalaceOnlyOneBid$,
      this.isSanctionedUtilization$,
    ]).pipe(
      map(
        ([bidMakeSense, canPlaceOnlyOneBid, isSanctionedUtilization]) =>
          bidMakeSense && !canPlaceOnlyOneBid && !isSanctionedUtilization,
      ),
      distinctUntilChanged(),
    );

    interval(1000)
      .pipe(
        startWith(0),
        map(() => this.limitService.checkOneHourBeforeAuctionReached(this.lot?.time)),
        distinctUntilChanged(),
        filter((cantCreateTranslation) => cantCreateTranslation),
        first(),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const createTranslationControl = this.form.controls.createTranslation;
        createTranslationControl.disable();
        createTranslationControl.setValue(false);
      });
  }

  showNavisRules(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('LOT_BIDDING_MODAL.NAVIS_RULES_TITLE'),
      nzContent: NavisRulesModalComponent,
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      amount: ['', Validators.required],
      comment: [''],
      dumpVersion: [''],
      lotId: [''],
      lotType: [this.lotType],
      conditionId: ['', [Validators.required]],
      groupId: [null],
      createTranslation: [false],
      notify: [false],
      commissionAgreement: [false],
      agreement: [true],
      needPriorityPass: [false],
      isGreenCorner: [false],
    });
  }

  openPriorityPassInfoModal(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('LOT_BIDDING_MODAL.NEED_PRIORITY_PASS'),
      nzContent: PriorityPassInfoComponent,
    });
  }

  isDisassemblyCheck(lot: BidCondition): void {
    this.isDisassembly = lot?.isDisassembly || false;
  }

  resetForm(defaultBidCondition?: BidCondition, createTranslation = false): void {
    this.form.reset({
      dumpVersion: this.lot.dumpVersion,
      lotId: this.lot.id,
      amount: this.lot.startPrice || 0,
      lotType: this.lotType,
      createTranslation: createTranslation,
      conditionId: defaultBidCondition?.id,
      groupId: null,
      comment: '',
      notify: false,
      agreement: true,
      commissionAgreement: false,
      needPriorityPass: false,
      isGreenCorner: false,
    });
  }

  createNewGroup(): void {
    const newGroup = this.fb.group({
      date: [
        DateTime.fromISO(this.lot.time, { zone: 'Asia/Tokyo' }).startOf('day').toISO(),
        Validators.required,
      ],
      letter: [null, Validators.required],
      quantity: [1, [Validators.min(1), Validators.max(9)]],
    });

    this.form.addControl('newGroup', newGroup);
    this.isNewGroup = true;
    this.driverService.getIsStartedGuide() && this.initDynamicallyCreatedDriverGuideSteps();
  }

  removeNewGroup(): void {
    this.form.removeControl('newGroup');
    this.isNewGroup = false;
    if (this.driverService.getIsStartedGuide()) {
      this.driverService.deleteStepByTitle(
        'lot-bid-modal',
        this.transService.instant('LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.FIRST.TITLE'),
      );
      this.driverService.deleteStepByTitle(
        'lot-bid-modal',
        this.transService.instant('LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.FIRST.TITLE'),
      );
      this.driverService.deleteStepByTitle(
        'lot-bid-modal',
        this.transService.instant('LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.CLOSE.TITLE'),
      );
      this.driverService.sortSteps();
      this.driverService.reset();
      this.driverService.start(1);
    }
  }

  async checkBirthdateAndPalaceBid(): Promise<void> {
    const currentUser = await this.biddingDataQueryRef.data
      .pipe(take(1), pluck('currentUser'))
      .toPromise();

    if (currentUser.birthdate) {
      await this.placeBid();
    } else {
      this.modalService
        .create({
          nzTitle: this.translate.instant('BIRTHDATE_MODAL.TITLE'),
          nzContent: SetBirthdateModalComponent,
          nzClosable: false,
          nzMaskClosable: false,
          nzComponentParams: {
            currentUserId: currentUser.id,
          },
        })
        .afterClose.subscribe(async (result) => {
          if (result) {
            await this.placeBid();
          }
        });
    }
  }

  private isRUSanctioned(currentUser: BidDataQuery['currentUser']): boolean {
    return (
      currentUser?.countryIso?.toLowerCase() === 'ru' &&
      (Number(this.lot.engineVolume) >= 1900 || isHybridOrElectric(this.lot))
    );
  }

  async placeBid(): Promise<void> {
    validateAllFormFields(this.form);

    const value = this.form.getRawValue();
    if (this.form.valid) {
      if (Number(value.amount) % 5000 !== 0) {
        this.notification.renderError('LOT_BIDDING_MODAL.BID_MUST_BE_ROUND');
        return;
      }

      const placingBidObservable = of(this.isNewGroup).pipe(
        switchMap((isNewGroup) =>
          iif(
            () => isNewGroup,
            this.mhs
              .handle(
                this.bidGroupCreateGQL,
                { input: value.newGroup },
                {
                  failureTranslationKey: 'LOT_BIDDING_MODAL.FAIL_CREATE_GROUP',
                  successTranslationKey: 'LOT_BIDDING_MODAL.SUCCESS_CREATE_GROUP',
                },
              )
              .pipe(map((data) => data.bidGroupCreate?.group?.id)),
            of(value.groupId || null),
          ),
        ),
        map(
          (groupId) =>
            ({
              amount: value.amount,
              comment: value.comment,
              conditionId: value.conditionId,
              createTranslation: value.createTranslation,
              lotId: value.lotId,
              lotType: value.lotType,
              notify: value.notify,
              lotSource: this.lotSource,
              withLimitsOverride: this.withLimitsOverride,
              needPriorityPass: value.needPriorityPass,
              isGreenCorner: value.isGreenCorner,
              groupId,
            } as BidCreateInput),
        ),
        switchMap((input) =>
          this.mhs.handle(
            this.bidCreateGQL,
            { input },
            {
              failureTranslationKey: 'LOT_BIDDING_MODAL.FAIL_CREATE_BID',
              renderSuccess: false,
            },
          ),
        ),
      );

      const reference = this.ohs.toReference(placingBidObservable);
      this.isPlacingBid$ = reference.loading;
      reference.data.subscribe((bidCreateData) => {
        this.afterSubmit.emit(true);
        this.modal.close();
        this.notification.renderSuccess(
          'Success',
          this.buildHintNotification(bidCreateData.bidCreate?.hint),
        );
      });
    } else {
      this.notification.renderError('LOT_BIDDING_MODAL.FILL_ALL_FIELDS');
    }
  }

  private buildHintNotification(hint?: PlaceBidHint): string {
    if (hint) {
      return this.translate.instant(hint.text, { triesLeft: hint.triesLeft });
    } else {
      return 'LOT_BIDDING_MODAL.SUCCESS_CREATE_BID';
    }
  }

  getBidGroupLabel(group: BidGroup): string {
    const base = `${group.letter} - ${group.quantity}`;
    const limitText = this.translate.instant('LOT_BIDDING_MODAL.LIMIT');

    if (group.lotsLimitReached && !this.currentUserLastBid) {
      return `${base} (${limitText})`;
    } else {
      return base;
    }
  }

  notificationSettings(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('SETTINGS.TITLE'),
      nzContent: SettingsModalComponent,
      nzComponentParams: {
        selectedIndex: 2,
      },
    });
  }

  onNextClickAmount(): void {
    this.groupBidsRef.nativeElement
      .querySelector('#newGroupBtn1')
      ?.classList.add('pulse-create-group-btn');
  }

  onPrevCalc(): void {
    const createGroupBtn = this.groupBidsRef.nativeElement.querySelector('#newGroupBtn1');
    if (createGroupBtn) {
      createGroupBtn?.classList.add('pulse-create-group-btn');
      this.driverService.reset();
      this.driverService.start(1);
    } else {
      this.driverService.moveTo(0);
    }
  }

  onNextCalc(): void {
    this.driverService.next();
  }

  initDynamicallyCreatedDriverGuideSteps(): void {
    const createGroupBtn = this.groupBidsRef?.nativeElement?.querySelector('#newGroupBtn1');
    if (createGroupBtn?.classList.contains('pulse-create-group-btn')) {
      createGroupBtn?.classList.remove('pulse-create-group-btn');
    }

    this.biddingDataQueryRef.data.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (this.driverService.getIsStartedGuide() && response?.isCanGroupBids) {
        if (
          !this.driverService.checkStepByDescr(
            this.transService.instant('LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.FIRST.DESCR'),
          )
        ) {
          const stepGroupBids1: DriveStep = {
            element: this.groupBidsRef.nativeElement,
            popover: {
              title: this.transService.instant('LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.FIRST.TITLE'),
              description: this.transService.instant(
                'LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.FIRST.DESCR',
              ),
              side: 'left',
              align: 'start',
              popoverClass: 'lot-bid-modal',
              onPrevClick: () => {
                this.delNewGroupRef?.elementRef?.nativeElement?.click();
                setTimeout(() => {
                  this.groupBidsRef.nativeElement
                    .querySelector('#newGroupBtn1')
                    ?.classList.add('pulse-create-group-btn');
                  this.driverService.moveTo(1);
                });
              },
              onNextClick: () => this.driverService.moveTo(3),
            },
          };
          this.driverService.addStep(2, stepGroupBids1);
        }
        if (
          !this.driverService.checkStepByDescr(
            this.transService.instant('LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.SECOND.DESCR'),
          )
        ) {
          const stepGroupBids2: DriveStep = {
            element: this.groupBidsRef.nativeElement,
            popover: {
              title: this.transService.instant(
                'LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.SECOND.TITLE',
              ),
              description: this.transService.instant(
                'LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.SECOND.DESCR',
              ),
              side: 'right',
              align: 'start',
              popoverClass: 'lot-bid-modal',
              onNextClick: () => {
                const deleteGroupBtn = this.delNewGroupRef?.elementRef?.nativeElement;
                deleteGroupBtn?.classList.add('pulse-delete-group-btn');
                this.driverService.next();
              },
            },
          };
          this.driverService.addStep(3, stepGroupBids2);
        }
        if (
          !this.driverService.checkStepByTitle(
            this.transService.instant('LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.CLOSE.TITLE'),
          )
        ) {
          const removeGroupBtn: DriveStep = {
            element: this.groupBidsRef?.nativeElement?.querySelector('#removeNewGroup'),
            popover: {
              title: this.transService.instant('LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.CLOSE.TITLE'),
              description: this.transService.instant(
                'LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.CLOSE.DESCR',
              ),
              side: 'right',
              align: 'start',
              popoverClass: 'lot-bid-modal',
              showButtons: ['close'],
            },
          };
          this.driverService.addStep(4, removeGroupBtn);
        }
        this.driverService.reset();
        this.driverService.start(2);
      }

      if (!this.hasTranslations && !this.mayNotBeProcessed && !response?.isSanctioned) {
        if (
          this.driverService.checkStepByDescr(
            this.transService.instant('LOT_BIDDING_MODAL.GUIDE.LOT_TRANS.FIRST.DESCR'),
          )
        ) {
          this.driverService.deleteStepByDescr(
            'lot-bid-modal',
            this.transService.instant('LOT_BIDDING_MODAL.GUIDE.LOT_TRANS.FIRST.DESCR'),
          );
        }
        if (
          this.driverService.checkStepByDescr(
            this.transService.instant('LOT_BIDDING_MODAL.GUIDE.LOT_TRANS.SECOND.DESCR'),
          )
        ) {
          this.driverService.deleteStepByDescr(
            'lot-bid-modal',
            this.transService.instant('LOT_BIDDING_MODAL.GUIDE.LOT_TRANS.SECOND.DESCR'),
          );
        }
        const createTransStep1: DriveStep = {
          element: document.querySelector('[formControlName="createTranslation"]'),
          popover: {
            title: this.transService.instant('LOT_BIDDING_MODAL.GUIDE.LOT_TRANS.FIRST.TITLE'),
            description: this.transService.instant('LOT_BIDDING_MODAL.GUIDE.LOT_TRANS.FIRST.DESCR'),
            side: 'left',
            align: 'start',
            popoverClass: 'lot-bid-modal',
          },
        };
        this.driverService.addStep(8, createTransStep1);
        const createTransStep2: DriveStep = {
          element: document.querySelector('[id="createTranslation9"]'),
          popover: {
            title: this.transService.instant('LOT_BIDDING_MODAL.GUIDE.LOT_TRANS.SECOND.TITLE'),
            description: this.transService.instant(
              'LOT_BIDDING_MODAL.GUIDE.LOT_TRANS.SECOND.DESCR',
            ),
            side: 'right',
            align: 'start',
            popoverClass: 'lot-bid-modal',
          },
        };
        this.driverService.addStep(9, createTransStep2);
      }
    });
  }

  startDriver(): void {
    const config: Config = {
      prevBtnText: this.transService.instant('DRIVER_CONFIG.PREV_BTN'),
      nextBtnText: this.transService.instant('DRIVER_CONFIG.NEXT_BTN'),
      doneBtnText: this.transService.instant('DRIVER_CONFIG.DONE_BTN'),
      onDestroyed: () => {
        const createGroupBtn = this.groupBidsRef?.nativeElement?.querySelector('#newGroupBtn1');
        const deleteGroupBtn = this.delNewGroupRef?.elementRef?.nativeElement;
        if (createGroupBtn?.classList.contains('pulse-create-group-btn')) {
          createGroupBtn?.classList.remove('pulse-create-group-btn');
        }
        if (deleteGroupBtn?.classList.contains('pulse-delete-group-btn')) {
          deleteGroupBtn?.classList.remove('pulse-delete-group-btn');
        }
        this.driverService.reset(true);
      },
    };
    this.driverService.setDriverName('lot-bid-modal');
    this.driverService.setConfig(config);
    this.driverService.deleteStepByTitle(
      'lot-bid-modal',
      this.translate.instant('LOT_BIDDING_MODAL.GUIDE.FINISH.TITLE'),
    );
    this.driverService.deleteStepByTitle(
      'lot-bid-modal',
      this.transService.instant('LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.FIRST.TITLE'),
    );
    this.driverService.deleteStepByTitle(
      'lot-bid-modal',
      this.transService.instant('LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.SECOND.TITLE'),
    );
    this.driverService.deleteStepByTitle(
      'lot-bid-modal',
      this.transService.instant('LOT_BIDDING_MODAL.GUIDE.BIDS_GROUPING.CLOSE.TITLE'),
    );
    this.driverService.addStep(this.driverService.getStepsLenByDriverName(), {
      popover: {
        title: this.translate.instant('LOT_BIDDING_MODAL.GUIDE.FINISH.TITLE'),
        description: this.translate.instant('LOT_BIDDING_MODAL.GUIDE.FINISH.DESCR'),
        popoverClass: 'lot-bid-modal',
      },
    });
    this.driverService.start();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
