<button nz-button nz-dropdown [nzDropdownMenu]="languages" nzTrigger="click">
  {{  language$ | async | uppercase }}
  <i nz-icon nzType="down"></i>
</button>
<nz-dropdown-menu #languages="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="changeLanguage('ru')">
      RU
    </li>
    <li nz-menu-item (click)="changeLanguage('en')">
      EN
    </li>
  </ul>
</nz-dropdown-menu>
