import { BirthdayGreetingsService } from '@akebono/core';
import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Alert, AlertTargetEnum } from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { AutoCompaniesGQL } from 'src/app/modules/graphql/service/graphql-cars-default-service';
import { AlertService } from 'src/app/modules/shared-components/alerts-list/alert.service';
import { ConnectTelegramService } from 'src/app/modules/shared-components/connect-telegram/connect-telegram.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnDestroy {
  listOfData = [];
  topCompanies = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((id) => id.toString());
  onlyTopCompanies = [];
  remainingCompanies: any = {};
  loading = true;
  alerts$: Observable<Alert[]>;

  private destroy$ = new Subject<void>();

  constructor(
    private title: Title,
    private autoCompaniesGQL: AutoCompaniesGQL,
    private alertService: AlertService,
    connectTelegramService: ConnectTelegramService,
    birthdayGreetingsService: BirthdayGreetingsService,
  ) {
    this.title.setTitle('ECar JP');
    this.alerts$ = this.alertService.getAlerts(null, AlertTargetEnum.MainPage);

    birthdayGreetingsService.checkBirthday();

    connectTelegramService.watch('settings', 'telegram').pipe(takeUntil(this.destroy$)).subscribe();

    this.autoCompaniesGQL
      .fetch({}, { fetchPolicy: 'cache-first' })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.loading = false;
          result.data.autoCompanies.nodes.forEach((c) => {
            if (c.companyId !== '0') {
              if (this.topCompanies.includes(c.companyId)) {
                this.onlyTopCompanies.push(c);
              } else {
                const letter = c.name[0];
                if (!this.remainingCompanies[letter]) {
                  this.remainingCompanies[letter] = [];
                }
                this.remainingCompanies[letter].push(c);
              }
            }
          });
        },
        (error) => {
          this.loading = false;
          console.log(error);
        },
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
