export const trucks = [
  {
    company: 'Daihatsu',
    model: 'Hijet Truck',
    frame:
      'S81P, S82P, S83P, S100P, S110P, S100C, S100CT, S110C, S110CT, S200P, S210P, S200C, S210C, S201P, S211P, S200P, S210P, S500P, S510P',
  },
  { company: 'Daihatsu', model: 'Hijet Truck 4W', frame: 'S81P, S82P, S83P' },
  { company: 'Daihatsu', model: 'Gran Max', frame: 'S403P, S413P' },
  {
    company: 'Mazda',
    model: 'Bongo',
    frame:
      'SE48T, SE88M, SEF8T, SE28M, SS28V, SSF8V, SS58V, SS88M, SE88M, SE88T, SE28T, SE28M, SEF8T, SE58T, SE88M, SE88T, SE28T, SE28M, SK82L, SK82T, SKP2L, SKP2T, SKF2L, SKF2T, SLP2L, SLP2T, SK22L, SK22T, S403F, S413F',
  },
  {
    company: 'Mazda',
    model: 'Bongo Brawny Truck',
    frame:
      'SE48T, SE88M, SEF8T, SE28M, SS28V, SSF8V, SS58V, SS88M, SE88M, SE88T, SE28T, SE28M, SEF8T, SE58T, SE88M, SE88T, SE28T, SE28M, SK82L, SK82T, SKP2L, SKP2T, SKF2L, SKF2T, SLP2L, SLP2T, SK22L, SK22T, S403F, S413F',
  },
  {
    company: 'Mazda',
    model: 'Bongo Truck',
    frame:
      'SE48T, SE88M, SEF8T, SE28M, SS28V, SSF8V, SS58V, SS88M, SE88M, SE88T, SE28T, SE28M, SEF8T, SE58T, SE88M, SE88T, SE28T, SE28M, SK82L, SK82T, SKP2L, SKP2T, SKF2L, SKF2T, SLP2L, SLP2T, SK22L, SK22T, S403F, S413F',
  },
  {
    company: 'Mazda',
    model: 'Bongo van',
    frame: 'SS88M',
  },
  { company: 'Mazda', model: 'Scrum', frame: 'DJ51T, DK51T, DG52T, DH52T, DG62T, DG63T, DG16T' },
  {
    company: 'Mitsubishi',
    model: 'Delica Van',
    frame: 'SK82LM, SK82TM, SKP2LM, SK22LM, SK22TM, SKF2LM, SKF2TM',
  },
  {
    company: 'Mitsubishi',
    model: 'Delica Truck',
    frame: 'SK82TM, SKF2LM, SKF2TM, SK22LM',
  },
  { company: 'Mitsubishi', model: 'Minicab', frame: 'U68T' },
  { company: 'Mitsubishi', model: 'Minicab MiEV', frame: 'U68T' },
  {
    company: 'Mitsubishi',
    model: 'Minicab Truck',
    frame:
      'L013P, L015G, U18T, U18TP, U19T, U41T, U41TP, U42T, U41V, U42V, U41T, U41TP, U42TP, U61T, U61TP, U62T, U62TP, U61T, U61TP, U62T, U62TP, U61T, U61TP, U62T, U62TP, U61T, U61TP, U62T, DS16T',
  },
  {
    company: 'Mitsubishi',
    model: 'Minicab',
    frame: 'U42T, U41V, U42V, U62T, U62TP, DS16T',
  },
  {
    company: 'Mitsubishi',
    model: 'Mini cab Truck',
    frame: 'U19T',
  },
  {
    company: 'Nissan',
    model: 'Clipper Truck',
    frame: 'U71T, U71TP, U72T, U72TP, U71V, U72V, DR16T',
  },
  { company: 'Nissan', model: 'NT100 Clipper', frame: 'DR16T' },
  {
    company: 'Nissan',
    model: 'Vanette Truck',
    frame: 'SK82LN, SK82TN, SKP2LN, SKP2TN, SKF2LN, SKF2TN, SK22LN, SK22TN',
  },
  {
    company: 'Subaru',
    model: 'Sambar',
    frame: 'KS3, KS4, KV3, KV4, TT1, TT2, TV1, TV2, S201J, S211J, S201H, S211H, S500J, S510J',
  },
  {
    company: 'Suzuki',
    model: 'Carry Truck',
    frame:
      'DA51T, DB51T. DC51B, DC51T, DD51B, DD51T, DC51T, DD51T, DA52T, DB52T, DA63T, DA65T, DA16T',
  },
  {
    company: 'Toyota',
    model: 'Lite Ace Truck',
    frame:
      'KM10, KM20, CM50, CM51, CM55, CM60, CM65, KM50, YM55, YM60, KM70, KM75, KM80, KM85, CM70, CM75, CM80, CM85, S402U, S412U, S403U, S413U',
  },
  { company: 'Toyota', model: 'Pixis Truck', frame: 'S201U, S211U, S500U, S510U' },
];
