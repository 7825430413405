import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject } from 'rxjs';
import { Limits, LimitsService } from 'src/app/services/limits.service';

import { AutoLotView, mergeSavedLotToAutoLotView } from '../../../const';
import {
  Lot,
  LotSource,
  LotTypeEnum,
  UserLotGQL,
  UserLotQuery,
} from '../../graphql/service/graphql-auto-main-service';
import { AutoLotQuery } from '../../graphql/service/graphql-cars-default-service';
import { LotBiddingModalComponent } from '../lot-bidding-modal/lot-bidding-modal.component';

@Component({
  selector: 'app-lot-place-bid-button-dumb',
  templateUrl: './lot-place-bid-button-dumb.component.html',
  styleUrls: ['./lot-place-bid-button-dumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotPlaceBidButtonDumbComponent {
  @Input() lot: AutoLotQuery['autoLot'];
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() limits: Limits;
  @Output() afterSubmit = new EventEmitter<boolean>();

  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private translate: TranslateService,
    private modalService: NzModalService,
    private limitsService: LimitsService,
    private userLotGQL: UserLotGQL,
  ) {}

  updateAutoLotViewBySavedData(lot: AutoLotView, savedLot?: UserLotQuery['lot']): AutoLotView {
    try {
      lot = mergeSavedLotToAutoLotView(lot, savedLot);
    } catch (e) {
      console.error(e);
    }

    lot.productionDate = savedLot.productionDate;
    lot.vin = savedLot.vin || lot?.vin;
    lot.images = lot?.images?.length > savedLot?.images?.length ? lot?.images : savedLot?.images;
    lot.negotiationsPrice = savedLot.negotiationsPrice;
    return lot;
  }

  async placeBid() {
    console.log('check time limit of', this.lot, this.lot.date);
    this.loading$.next(true);
    let autoLot = {
      ...this.lot,
      images: this.lot.images.split('#'),
      auctionName: this.lot.autoAuction?.name || 'MISSING',
      companyId: this.lot.companyRef,
      modelId: this.lot.modelNameRef,
      vin: this.lot.vin || (this.lot.frame ? this.lot.frame + '-' : ''),
    } as AutoLotView;
    const userLotQuery = await this.userLotGQL
      .fetch(
        {
          id: this.lot.id,
          lotSource: this.lotSource,
          subgroup: this.lotType,
        },
        { fetchPolicy: 'network-only' },
      )
      .toPromise();

    if (userLotQuery.data.lot) {
      autoLot = this.updateAutoLotViewBySavedData(autoLot, userLotQuery.data.lot);
    }

    this.loading$.next(false);

    this.modalService.create({
      nzTitle: this.translate.instant('LOT_BIDDING_MODAL.PLACE_YOUR_BID'),
      nzContent: LotBiddingModalComponent,
      nzFooter: null,
      nzComponentParams: {
        lot: autoLot,
        lotType: this.lotType,
        lotSource: this.lotSource,
        renderCalculator: false,
        limits: this.limitsService.getLimits(autoLot, userLotQuery.data.lot as Lot),
        hasTranslations: !!userLotQuery?.data?.lot?.translations?.length,
        currentUserLastBid: userLotQuery?.data?.lot?.currentUserLastBid,
        afterSubmit: this.afterSubmit,
      },
    });
  }
}
