<form [formGroup]="form">
  <nz-table
    #table
    [nzTotal]="1"
    [nzData]="[1]"
    nzSize="small"
    [nzLoading]="loading"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
  >
    <tbody>
      <tr>
        <td>
          {{
            'TRANSLATION_SETTINGS.PREFERRED_LANGUAGE_FOR_TRANSLATION_AND_NOTIFICATION' | translate
          }}
        </td>
        <td class="no-wrap">
          <nz-radio-group nzButtonStyle="solid" formControlName="preferredLang">
            <label
              *ngFor="let lang of preferredLangs"
              [nzDisabled]="!canSetLanguage(lang)"
              nz-radio-button
              [nzValue]="lang"
            >
              {{ lang | uppercase | translate }}
            </label>
          </nz-radio-group>
        </td>
      </tr>
      <tr>
        <td>
          {{ 'TRANSLATION_SETTINGS.DEFAULT_TRANSLATION_MEDIA_TYPE' | translate }}
        </td>
        <td class="no-wrap">
          <nz-radio-group nzButtonStyle="solid" formControlName="defaultTranslationMediaType">
            <label *ngFor="let type of translationMediaTypes" nz-radio-button [nzValue]="type">
              {{ 'TRANSLATION_SETTINGS.TRANSLATION_MEDIA_TYPES.' + type | uppercase | translate }}
            </label>
          </nz-radio-group>
        </td>
      </tr>
      <tr>
        <td>
          {{ 'TRANSLATION_SETTINGS.DEFAULT_AUDIO_TRANSLATION_SPEED' | translate }}
        </td>
        <td class="no-wrap">
          <nz-select formControlName="defaultAudioTranslationSpeed">
            <nz-option
              *ngFor="let speed of audioTranslationSpeeds"
              nzLabel="x{{ speed }}"
              [nzValue]="speed"
            ></nz-option>
          </nz-select>
        </td>
      </tr>
    </tbody>
  </nz-table>
</form>
