import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { LotIconEnum } from 'src/app/modules/graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-icon',
  templateUrl: './lot-icon.component.html',
  styleUrls: ['./lot-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotIconComponent {
  @Input() icon: LotIconEnum;
  @Input() enabled = false;
  @Input()
  @HostBinding('class.clickable')
  clickable = false;
  @Output() enabledChange = new EventEmitter<boolean>();

  colorByIcon: Record<LotIconEnum, 'yellow' | 'red' | 'blue'> = {
    [LotIconEnum.Check]: 'yellow',
    [LotIconEnum.Damaged]: 'yellow',
    [LotIconEnum.DashboardLampOn]: 'yellow',
    [LotIconEnum.Forklift]: 'yellow',
    [LotIconEnum.NotLeftDrive]: 'yellow',
    [LotIconEnum.Repainted]: 'yellow',
    [LotIconEnum.Sanctions]: 'red',
    [LotIconEnum.Drowned]: 'red',
    [LotIconEnum.EquippedForDisabled]: 'blue',
  };

  @HostBinding('class')
  get color(): 'yellow' | 'red' | 'blue' | 'disabled' {
    return this.enabled ? this.colorByIcon[this.icon] : 'disabled';
  }

  @HostListener('click')
  toggle(): void {
    if (this.clickable) {
      this.enabled = !this.enabled;
      this.enabledChange.emit(this.enabled);
    }
  }
}
