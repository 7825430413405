import { MutationHandlingServiceV2 } from '@akebono/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { isHybridOrElectric } from 'src/app/hybrids';
import { Limits } from 'src/app/services/limits.service';

import { AutoLotView } from '../../../../../const';
import {
  LotProductionDateCheckRequestCreateGQL,
  LotProductionDateFetchGQL,
  LotSource,
  LotTypeEnum,
  UserLotQuery,
} from '../../../../graphql/service/graphql-auto-main-service';
import { CheckProductionDatePayloadService } from '../../services/check-production-date-payload.service';
import { ProductionDatePayload } from '../../types/production-date-payload.type';

@Component({
  selector: 'app-lot-check-production-date-input',
  templateUrl: './lot-check-production-date-input.component.html',
  styleUrls: ['./lot-check-production-date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotCheckProductionDateInputComponent implements OnInit, OnDestroy {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() fullVin?: boolean;
  @Input() limits: Limits;
  @Input() currentUser: UserLotQuery['currentUser'];
  @Output() inputFocus = new EventEmitter<void>();

  form: FormGroup;

  checkFail = false;
  loading$ = of(false);
  payload$: Observable<ProductionDatePayload>;
  canCheckProductionDate$ = of(true);
  cantRequestCheckProductionDate$ = of(false);

  private destroy$ = new Subject();

  allowedCompanies = [
    'TOYOTA',
    'NISSAN',
    'MAZDA',
    'HONDA',
    'SUBARU',
    'DAIHATSU',
    'SUZUKI',
    'ISUZU',
    'MITSUBISHI',
    'HINO',
    'LEXUS',
    'DATSUN',
  ];
  allowed = false;

  constructor(
    private fb: FormBuilder,
    private mhs: MutationHandlingServiceV2,
    private checkProductionDatePayloadService: CheckProductionDatePayloadService,
    private lotProductionDateFetchGQL: LotProductionDateFetchGQL,
    private lotProductionDateCheckRequestCreateGQL: LotProductionDateCheckRequestCreateGQL,
  ) {
    this.form = this.fb.group({
      vinManufacture: [null, [Validators.required]],
    });
  }

  get isRUSanctioned() {
    return (
      this.currentUser?.countryIso?.toLowerCase() == 'ru' &&
      (Number(this.lot.engineVolume) >= 1900 || isHybridOrElectric(this.lot))
    );
  }

  ngOnInit(): void {
    this.allowed =
      (this.allowedCompanies.includes(this.lot.company) && !this.isRUSanctioned) ||
      this.currentUser.isAuctioneer;
    this.form.patchValue({
      vinManufacture: this.lot.vin,
    });

    if (this.fullVin && this.lot.vin && !this.lot.productionDate) {
      this.checkDateOfManufacture();
    }

    this.payload$ = this.checkProductionDatePayloadService.payload$;
    this.checkProductionDatePayloadService.emit(this.lot);
    this.canCheckProductionDate$ = combineLatest([of(this.currentUser), this.payload$]).pipe(
      map(([currentUser, payload]) => {
        const payloadExists = Boolean(payload?.length);

        if (currentUser.canCheckProductionDate || currentUser.canRecheckProductionDate) {
          if (payloadExists) {
            return currentUser.canRecheckProductionDate;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }),
    );

    this.cantRequestCheckProductionDate$ = combineLatest([
      of(this.currentUser),
      this.limits.productionDateCheckRequestLimitReached$,
    ]).pipe(
      map(([currentUser, productionDateCheckRequestLimitReached]) => {
        if (currentUser.isAuctioneer) {
          return false;
        }

        return productionDateCheckRequestLimitReached ?? false;
      }),
      shareReplay(1),
    );
  }

  submit(): void {
    if (this.checkFail && !this.currentUser.isAuctioneer) {
      this.createProductionDateCheckRequest();
    } else {
      this.checkDateOfManufacture();
    }
  }

  checkDateOfManufacture(): void {
    const mutationRef = this.mhs.mutate(
      this.lotProductionDateFetchGQL,
      {
        input: {
          lotId: this.lot.id,
          lotType: this.lotType,
          lotSource: this.lotSource,
          vin: this.form.value.vinManufacture || this.lot.vin,
        },
      },
      {
        failureTranslationKey: 'PRODUCTION_DATE_CHECK_ERROR',
      },
    );

    this.loading$ = mutationRef.loading;
    mutationRef.data
      .pipe(
        map((result) => result.lotProductionDateFetch.lot),
        tap(() =>
          document
            .getElementById('lots-check-production-date-result')
            ?.scrollIntoView({ behavior: 'smooth' }),
        ),
        catchError(() => {
          this.checkFail = true;
          return of(null);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((data) => this.checkProductionDatePayloadService.emit(data));
  }

  createProductionDateCheckRequest(): void {
    this.loading$ = this.mhs.mutate(
      this.lotProductionDateCheckRequestCreateGQL,
      {
        input: {
          lotId: this.lot.id,
          lotType: this.lotType,
          lotSource: this.lotSource,
          vin: this.form.value.vinManufacture,
        },
      },
      {
        refetch: false,
        successTranslationKey: 'USER_REQUESTS.CREATE_SUCCESS',
        failureTranslationKey: 'USER_REQUESTS.CREATE_FAILD',
      },
    ).loading;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
