import { QueryHandlingService } from '@akebono/core';
import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AutoLotView, ManualLotData } from '../const';
import { isHybridOrElectric } from '../hybrids';
import {
  SanctionsServiceDataGQL,
  SanctionsServiceDataQuery,
} from '../modules/graphql/service/graphql-auto-main-service';
import { AutoLot } from '../modules/graphql/service/graphql-cars-default-service';

@Injectable({
  providedIn: 'root',
})
export class SanctionsService implements OnDestroy {
  private readonly currentUser$: Observable<SanctionsServiceDataQuery['currentUser']>;
  private readonly subscription: Subscription;

  constructor(
    private readonly qhs: QueryHandlingService,
    private readonly sanctionsServiceDataGQL: SanctionsServiceDataGQL,
  ) {
    this.currentUser$ = this.qhs.fetch(this.sanctionsServiceDataGQL, {}, 'cache-first').data.pipe(
      map((data) => data.currentUser),
      shareReplay(1),
    );

    this.subscription = this.currentUser$.subscribe();
  }

  isLotSanctioned$(lot: AutoLotView | AutoLot | ManualLotData): Observable<boolean> {
    return combineLatest([of(lot), this.currentUser$]).pipe(
      map(([lot, currentUser]) =>
        this.isLotSanctioned(
          lot,
          currentUser.countryIso,
          currentUser.isAuctioneer || currentUser.isTranslator,
        ),
      ),
      shareReplay(1),
    );
  }

  isLotSanctioned(
    lot: AutoLotView | AutoLot | ManualLotData,
    userCountryIso: string,
    isEmployee: boolean,
  ): boolean {
    if (!lot || !userCountryIso) {
      return false;
    }

    const normalizedLot = this.normalizeLot(lot);
    const canApplySanctionsCheck = this.canApplySanctionsCheck(userCountryIso, isEmployee);

    return (
      canApplySanctionsCheck &&
      (normalizedLot.engineVolume >= 1900 || isHybridOrElectric(normalizedLot))
    );
  }

  private normalizeLot(lot: any): AutoLotView {
    return <AutoLotView>{
      company: lot.company || lot.companyEn,
      modelName: lot.modelName || lot.modelNameEn,
      frame: lot.frame,
      engineVolume: Number(lot.engineVolume),
    };
  }

  private canApplySanctionsCheck(userCountryIso: string, isEmployee: boolean): boolean {
    if (isEmployee) {
      return false;
    } else {
      return userCountryIso.toLowerCase() === 'ru';
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
