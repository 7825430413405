import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A valid DateTime, transported as a iso8601 string */
  DateTime: any;
  /** An lot ID */
  LotID: any;
  /** Represents non-fractional signed whole numeric values. Since the value may exceed the size of a 32-bit integer, it's encoded as a string. */
  BigInt: any;
  /** A valid JSON object */
  JSON: any;
};

export type Query = {
  __typename?: 'Query';
  lotMarksList?: Maybe<UserLotMarksList>;
  userChannel?: Maybe<UserChannel>;
  userLotMark?: Maybe<UserLotMark>;
};


export type QueryLotMarksListArgs = {
  id: Scalars['String'];
};


export type QueryUserChannelArgs = {
  path: Scalars['String'];
};


export type QueryUserLotMarkArgs = {
  id: Scalars['ID'];
};

export type UserLotMarksList = {
  __typename?: 'UserLotMarksList';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Scalars['String']>>;
  lotMarks?: Maybe<Array<UserLotMark>>;
  path?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type UserLotMark = {
  __typename?: 'UserLotMark';
  id?: Maybe<Scalars['ID']>;
  lot?: Maybe<Lot>;
  viewsCount?: Maybe<Scalars['Int']>;
};

export type Lot = {
  __typename?: 'Lot';
  auctionDate?: Maybe<Scalars['DateTime']>;
  auctionDatetime?: Maybe<Scalars['DateTime']>;
  auctionName?: Maybe<Scalars['String']>;
  auctionSystem?: Maybe<Scalars['String']>;
  biddersCount?: Maybe<Scalars['Int']>;
  bidsCount?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  colorEn?: Maybe<Scalars['String']>;
  companyEn?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endPriceNum?: Maybe<Scalars['String']>;
  engineVolumeNum?: Maybe<Scalars['Int']>;
  equipmentEn?: Maybe<Scalars['String']>;
  icons?: Maybe<Array<LotIconEnum>>;
  id?: Maybe<Scalars['LotID']>;
  images?: Maybe<Array<Scalars['String']>>;
  inFavoritesCount?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  mileageNum?: Maybe<Scalars['String']>;
  modelDetailsEn?: Maybe<Scalars['String']>;
  modelEn?: Maybe<Scalars['String']>;
  modelGradeEn?: Maybe<Scalars['String']>;
  modelTypeEn?: Maybe<Scalars['String']>;
  modelYearEn?: Maybe<Scalars['String']>;
  negotiationsPrice?: Maybe<Scalars['BigInt']>;
  productionDate?: Maybe<Scalars['DateTime']>;
  productionDatePayload?: Maybe<Scalars['JSON']>;
  ratingEn?: Maybe<Scalars['String']>;
  soldPrice?: Maybe<Scalars['BigInt']>;
  startPriceNum?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subgroup?: Maybe<LotTypeEnum>;
  thumbnails?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<LotTranslation>>;
  transmissionEn?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  viewsCount?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export enum LotIconEnum {
  Check = 'check',
  Damaged = 'damaged',
  DashboardLampOn = 'dashboard_lamp_on',
  Drowned = 'drowned',
  EquippedForDisabled = 'equipped_for_disabled',
  Forklift = 'forklift',
  NotLeftDrive = 'not_left_drive',
  Repainted = 'repainted',
  Sanctions = 'sanctions'
}

export enum LotTypeEnum {
  Auto = 'auto',
  Moto = 'moto',
  Oneprice = 'oneprice'
}

export type LotTranslation = {
  __typename?: 'LotTranslation';
  createdAt?: Maybe<Scalars['DateTime']>;
  file?: Maybe<S3File>;
  id?: Maybe<Scalars['ID']>;
  language?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type S3File = {
  __typename?: 'S3File';
  filename?: Maybe<Scalars['String']>;
  fullPath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type UserChannel = {
  __typename?: 'UserChannel';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Scalars['String']>>;
  path?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  userLotMarksLists?: Maybe<Array<UserLotMarksList>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  userLotMarkUpdate?: Maybe<UserLotMarkUpdatePayload>;
};


export type MutationUserLotMarkUpdateArgs = {
  input: UserLotMarkUpdateInput;
};

/** Autogenerated input type of UserLotMarkUpdate */
export type UserLotMarkUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of UserLotMarkUpdate. */
export type UserLotMarkUpdatePayload = {
  __typename?: 'UserLotMarkUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  userLotMark?: Maybe<UserLotMark>;
};

export type Error = {
  __typename?: 'Error';
  backtrace?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type LotCountersFragment = { __typename?: 'Lot', id?: Maybe<any>, bidsCount?: Maybe<number>, biddersCount?: Maybe<number>, viewsCount?: Maybe<number>, inFavoritesCount?: Maybe<number> };

export type LotTranslationFragment = { __typename?: 'LotTranslation', id?: Maybe<string>, language?: Maybe<string>, message?: Maybe<string>, file?: Maybe<{ __typename?: 'S3File', filename?: Maybe<string>, fullPath?: Maybe<string>, id?: Maybe<string>, key?: Maybe<string> }> };

export type LotFragment = { __typename?: 'Lot', id?: Maybe<any>, vin?: Maybe<string>, code?: Maybe<string>, year?: Maybe<string>, link?: Maybe<string>, icons?: Maybe<Array<LotIconEnum>>, images?: Maybe<Array<string>>, status?: Maybe<string>, colorEn?: Maybe<string>, modelEn?: Maybe<string>, ratingEn?: Maybe<string>, companyEn?: Maybe<string>, createdAt?: Maybe<any>, soldPrice?: Maybe<any>, updatedAt?: Maybe<any>, mileageNum?: Maybe<string>, thumbnails?: Maybe<Array<string>>, auctionDate?: Maybe<any>, auctionName?: Maybe<string>, endPriceNum?: Maybe<string>, equipmentEn?: Maybe<string>, modelTypeEn?: Maybe<string>, modelYearEn?: Maybe<string>, modelGradeEn?: Maybe<string>, auctionSystem?: Maybe<string>, startPriceNum?: Maybe<string>, modelDetailsEn?: Maybe<string>, productionDate?: Maybe<any>, transmissionEn?: Maybe<string>, auctionDatetime?: Maybe<any>, engineVolumeNum?: Maybe<number>, negotiationsPrice?: Maybe<any>, productionDatePayload?: Maybe<any> };

export type UserLotMarkUpdateMutationVariables = Exact<{
  input: UserLotMarkUpdateInput;
}>;


export type UserLotMarkUpdateMutation = { __typename?: 'Mutation', userLotMarkUpdate?: Maybe<{ __typename?: 'UserLotMarkUpdatePayload', error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type LotMarksListQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type LotMarksListQuery = { __typename?: 'Query', lotMarksList?: Maybe<{ __typename?: 'UserLotMarksList', id?: Maybe<string>, path?: Maybe<string>, title?: Maybe<string>, createdAt?: Maybe<any>, lotMarks?: Maybe<Array<{ __typename?: 'UserLotMark', id?: Maybe<string>, viewsCount?: Maybe<number>, lot?: Maybe<{ __typename?: 'Lot', id?: Maybe<any>, vin?: Maybe<string>, code?: Maybe<string>, year?: Maybe<string>, link?: Maybe<string>, icons?: Maybe<Array<LotIconEnum>>, images?: Maybe<Array<string>>, status?: Maybe<string>, colorEn?: Maybe<string>, modelEn?: Maybe<string>, ratingEn?: Maybe<string>, companyEn?: Maybe<string>, createdAt?: Maybe<any>, soldPrice?: Maybe<any>, updatedAt?: Maybe<any>, mileageNum?: Maybe<string>, thumbnails?: Maybe<Array<string>>, auctionDate?: Maybe<any>, auctionName?: Maybe<string>, endPriceNum?: Maybe<string>, equipmentEn?: Maybe<string>, modelTypeEn?: Maybe<string>, modelYearEn?: Maybe<string>, modelGradeEn?: Maybe<string>, auctionSystem?: Maybe<string>, startPriceNum?: Maybe<string>, modelDetailsEn?: Maybe<string>, productionDate?: Maybe<any>, transmissionEn?: Maybe<string>, auctionDatetime?: Maybe<any>, engineVolumeNum?: Maybe<number>, negotiationsPrice?: Maybe<any>, productionDatePayload?: Maybe<any> }> }>> }> };

export type UserLotMarkQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserLotMarkQuery = { __typename?: 'Query', userLotMark?: Maybe<{ __typename?: 'UserLotMark', id?: Maybe<string>, viewsCount?: Maybe<number>, lot?: Maybe<{ __typename?: 'Lot', id?: Maybe<any>, vin?: Maybe<string>, code?: Maybe<string>, year?: Maybe<string>, link?: Maybe<string>, icons?: Maybe<Array<LotIconEnum>>, images?: Maybe<Array<string>>, status?: Maybe<string>, colorEn?: Maybe<string>, modelEn?: Maybe<string>, ratingEn?: Maybe<string>, companyEn?: Maybe<string>, createdAt?: Maybe<any>, soldPrice?: Maybe<any>, updatedAt?: Maybe<any>, mileageNum?: Maybe<string>, thumbnails?: Maybe<Array<string>>, auctionDate?: Maybe<any>, auctionName?: Maybe<string>, endPriceNum?: Maybe<string>, equipmentEn?: Maybe<string>, modelTypeEn?: Maybe<string>, modelYearEn?: Maybe<string>, modelGradeEn?: Maybe<string>, auctionSystem?: Maybe<string>, startPriceNum?: Maybe<string>, modelDetailsEn?: Maybe<string>, productionDate?: Maybe<any>, transmissionEn?: Maybe<string>, auctionDatetime?: Maybe<any>, engineVolumeNum?: Maybe<number>, negotiationsPrice?: Maybe<any>, productionDatePayload?: Maybe<any>, bidsCount?: Maybe<number>, biddersCount?: Maybe<number>, viewsCount?: Maybe<number>, inFavoritesCount?: Maybe<number>, translations?: Maybe<Array<{ __typename?: 'LotTranslation', id?: Maybe<string>, language?: Maybe<string>, message?: Maybe<string>, file?: Maybe<{ __typename?: 'S3File', filename?: Maybe<string>, fullPath?: Maybe<string>, id?: Maybe<string>, key?: Maybe<string> }> }>> }> }> };

export type UserChannelQueryVariables = Exact<{
  path: Scalars['String'];
}>;


export type UserChannelQuery = { __typename?: 'Query', userChannel?: Maybe<{ __typename?: 'UserChannel', id?: Maybe<string>, path?: Maybe<string>, title?: Maybe<string>, userId?: Maybe<string>, images?: Maybe<Array<string>>, createdAt?: Maybe<any>, publishedAt?: Maybe<any>, description?: Maybe<string>, userLotMarksLists?: Maybe<Array<{ __typename?: 'UserLotMarksList', id?: Maybe<string>, path?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, createdAt?: Maybe<any>, description?: Maybe<string>, lotMarks?: Maybe<Array<{ __typename?: 'UserLotMark', id?: Maybe<string>, viewsCount?: Maybe<number>, lot?: Maybe<{ __typename?: 'Lot', id?: Maybe<any>, vin?: Maybe<string>, code?: Maybe<string>, year?: Maybe<string>, link?: Maybe<string>, icons?: Maybe<Array<LotIconEnum>>, images?: Maybe<Array<string>>, status?: Maybe<string>, colorEn?: Maybe<string>, modelEn?: Maybe<string>, ratingEn?: Maybe<string>, companyEn?: Maybe<string>, createdAt?: Maybe<any>, soldPrice?: Maybe<any>, updatedAt?: Maybe<any>, mileageNum?: Maybe<string>, thumbnails?: Maybe<Array<string>>, auctionDate?: Maybe<any>, auctionName?: Maybe<string>, endPriceNum?: Maybe<string>, equipmentEn?: Maybe<string>, modelTypeEn?: Maybe<string>, modelYearEn?: Maybe<string>, modelGradeEn?: Maybe<string>, auctionSystem?: Maybe<string>, startPriceNum?: Maybe<string>, modelDetailsEn?: Maybe<string>, productionDate?: Maybe<any>, transmissionEn?: Maybe<string>, auctionDatetime?: Maybe<any>, engineVolumeNum?: Maybe<number>, negotiationsPrice?: Maybe<any>, productionDatePayload?: Maybe<any> }> }>> }>> }> };

export const LotCountersFragmentDoc = gql`
    fragment LotCounters on Lot {
  id
  bidsCount
  biddersCount
  viewsCount
  inFavoritesCount
}
    `;
export const LotTranslationFragmentDoc = gql`
    fragment LotTranslation on LotTranslation {
  id
  language
  message
  file {
    filename
    fullPath
    id
    key
  }
}
    `;
export const LotFragmentDoc = gql`
    fragment lot on Lot {
  id
  vin
  code
  year
  link
  icons
  images
  status
  colorEn
  modelEn
  ratingEn
  companyEn
  createdAt
  soldPrice
  updatedAt
  mileageNum
  thumbnails
  auctionDate
  auctionName
  endPriceNum
  equipmentEn
  modelTypeEn
  modelYearEn
  modelGradeEn
  auctionSystem
  startPriceNum
  modelDetailsEn
  productionDate
  transmissionEn
  auctionDatetime
  engineVolumeNum
  negotiationsPrice
  productionDatePayload
}
    `;
export const UserLotMarkUpdateDocument = gql`
    mutation userLotMarkUpdate($input: UserLotMarkUpdateInput!) {
  userLotMarkUpdate(input: $input) {
    error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserLotMarkUpdateGQL extends Apollo.Mutation<UserLotMarkUpdateMutation, UserLotMarkUpdateMutationVariables> {
    document = UserLotMarkUpdateDocument;
    client = 'auto-shared';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LotMarksListDocument = gql`
    query lotMarksList($id: String!) {
  lotMarksList(id: $id) {
    id
    path
    title
    lotMarks {
      id
      lot {
        ...lot
      }
      viewsCount
    }
    createdAt
  }
}
    ${LotFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LotMarksListGQL extends Apollo.Query<LotMarksListQuery, LotMarksListQueryVariables> {
    document = LotMarksListDocument;
    client = 'auto-shared';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserLotMarkDocument = gql`
    query userLotMark($id: ID!) {
  userLotMark(id: $id) {
    id
    viewsCount
    lot {
      ...lot
      ...LotCounters
      translations {
        ...LotTranslation
      }
    }
  }
}
    ${LotFragmentDoc}
${LotCountersFragmentDoc}
${LotTranslationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserLotMarkGQL extends Apollo.Query<UserLotMarkQuery, UserLotMarkQueryVariables> {
    document = UserLotMarkDocument;
    client = 'auto-shared';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserChannelDocument = gql`
    query userChannel($path: String!) {
  userChannel(path: $path) {
    id
    path
    title
    userId
    images
    createdAt
    publishedAt
    description
    userLotMarksLists {
      id
      path
      title
      images
      createdAt
      description
      lotMarks {
        id
        lot {
          ...lot
        }
        viewsCount
      }
    }
  }
}
    ${LotFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserChannelGQL extends Apollo.Query<UserChannelQuery, UserChannelQueryVariables> {
    document = UserChannelDocument;
    client = 'auto-shared';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    