import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { from, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AutoLotView, HTMLInputEvent } from '../../../const';
import { MutationHandlingService } from '../../../services/mutation-handling.service';
import { UploadsService } from '../../../services/uploads.service';
import {
  LotTypeEnum,
  LotUpdateGQL,
  UserLotQuery,
} from '../../graphql/service/graphql-auto-main-service';
import { LotSource } from '../../graphql/service/graphql-cars-default-service';

@Component({
  selector: 'app-lot-add-photo-button',
  templateUrl: './lot-add-photo-button.component.html',
  styleUrls: ['./lot-add-photo-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotAddPhotoButtonComponent implements OnDestroy {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Output() started = new EventEmitter<void>();
  @Output() finish = new EventEmitter<void>();

  @HostBinding('style.display') display: 'none' | 'inline-block' = 'none';

  isAddingPhotos = false;

  private destroy$ = new Subject();

  constructor(
    private uploads: UploadsService,
    private mutationHandlingService: MutationHandlingService,
    private lotUpdateGQL: LotUpdateGQL,
  ) {}

  @Input()
  set currentUser(currentUser: UserLotQuery['currentUser']) {
    this.display = currentUser.isAuctioneer ? 'inline-block' : 'none';
  }

  addPhotos(event: HTMLInputEvent) {
    this.isAddingPhotos = true;
    of(event)
      .pipe(
        map((ev) => Array.from(ev.target.files) as File[]),
        switchMap((files) => from(files)),
        mergeMap((file) => this.uploads.uploadFileReactive(file, file.name)),
        toArray(),
        tap(() => this.started.emit()),
        switchMap((keys) =>
          this.mutationHandlingService.handle(
            this.lotUpdateGQL,
            {
              input: {
                lotId: this.lot.id,
                lotType: this.lotType,
                lotSource: this.lotSource,
                photos: [
                  ...keys.map((src) =>
                    `https://img.${environment.domain}/${src}`.replace(
                      'img.lots.loc/',
                      'img.twilight.link/',
                    ),
                  ),
                  ...this.lot.images,
                ],
              },
            },
            {
              successTranslationKey: 'UPLOAD_PHOTOS.SUCCESS',
              failureTranslationKey: 'UPLOAD_PHOTOS.FAIL',
            },
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe({
        error: (error) => console.log(error),
        complete: () => {
          this.isAddingPhotos = false;
          this.finish.emit();
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
