<ng-container *ngIf="(isGuide$ | async) === false">
  <ng-container *ngIf="isManager">
    <nz-spin [nzSpinning]="loading$ | async">
      <section>
        <app-lot-icon
          *ngFor="let group of icons.controls; index as i"
          [icon]="group.value.icon"
          [clickable]="true"
          [enabled]="group.value.enabled"
          (enabledChange)="group.controls.enabled.setValue($event)"
        ></app-lot-icon>
      </section>
    </nz-spin>
  </ng-container>

  <section *ngIf="!isManager">
    <ng-container *ngFor="let icon of lotIcons">
      <app-lot-icon
        *ngIf="!invisibleIcons.has(icon)"
        [icon]="icon"
        [enabled]="true"
        [clickable]="false"
      ></app-lot-icon>
    </ng-container>
  </section>
</ng-container>

<section class="lot-icons-guide" *ngIf="isGuide$ | async">
  <ng-container *ngFor="let icon of guideIcons">
    <app-lot-icon
      [icon]="icon"
      [enabled]="true"
      [clickable]="false"
    ></app-lot-icon>
  </ng-container>
</section>
