<nz-content>
  <div nz-row>
    <div nz-col nzSpan="24" class="card-lot-type content">
      <div nz-row nzJustify="start" [nzGutter]="[10, 10]">
        <div nz-col nzSpan="12" nzMd="6" nzLg="4">
          <a routerLink="/auto">
            <nz-card class="main-card">
              <p class="card-header" translate>{{ 'AUTO' | translate }}</p>
              <img src="/assets/auto2.jpg" class="card-img" />
            </nz-card>
          </a>
        </div>
        <div nz-col nzSpan="12" nzMd="6" nzLg="4">
          <a routerLink="/moto">
            <nz-card class="main-card">
              <p class="card-header" translate>{{ 'MOTO' | translate }}</p>
              <img src="/assets/moto2.jpg" class="card-img" style="object-fit: contain" />
            </nz-card>
          </a>
        </div>
        <div nz-col nzSpan="12" nzMd="6" nzLg="4">
          <a routerLink="/oneprice">
            <nz-card class="main-card">
              <p class="card-header" translate>{{ 'ONEPRICE' | translate }}</p>
              <img src="/assets/mazda2.jpeg" class="card-img" />
            </nz-card>
          </a>
        </div>
        <div nz-col nzSpan="12" nzMd="6" nzLg="4">
          <a routerLink="/manual-lots">
            <nz-card class="main-card">
              <p class="card-header" translate>ARAI KENKI</p>
              <img src="/assets/special2.jpg" class="card-img" />
            </nz-card>
          </a>
        </div>
        <div nz-col nzSpan="12" nzMd="6" nzLg="4">
          <a routerLink="/stats-auto">
            <nz-card class="main-card">
              <p class="card-header" translate>Statistics</p>
              <img src="/assets/stats2.jpg" class="card-img" />
            </nz-card>
          </a>
        </div>
      </div>
    </div>
  </div>
  <nz-row *ngIf="loading" nzType="flex" nzJustify="center" nzAlign="middle" style="height: 90vh">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </nz-row>
  <div class="content" *ngIf="!loading">
    <div class="companies-links">
      <a nz-button nzType="primary" nzSize="small" routerLink="/auto">Any</a>
      <a
        nz-button
        nzType="primary"
        nzSize="small"
        class="top"
        *ngFor="let company of onlyTopCompanies"
        [routerLink]="['/auto']"
        [queryParams]="{ company: company.companyId }"
      >
        {{ company.name }}
      </a>
    </div>
    <nz-divider></nz-divider>
    <div class="purchase-statistics">
      <app-user-purchase-statistics></app-user-purchase-statistics>
    </div>
    <div class="columns">
      <div class="no-break" *ngFor="let item of remainingCompanies | keyvalue">
        <h3>{{ item.key }}</h3>
        <ng-container *ngFor="let company of item.value">
          <a
            [routerLink]="['/auto']"
            [queryParams]="{ company: company.companyId }"
            class="link-item"
          >
            {{ company.name }}
          </a>
          <br />
        </ng-container>
      </div>
    </div>
  </div>
</nz-content>
<app-main-alerts [alerts]="alerts$ | async"></app-main-alerts>
<app-country-config></app-country-config>
