import { QueryHandlingService } from '@akebono/core';
import { Injectable } from '@angular/core';
import { combineLatest, from, Observable } from 'rxjs';
import { find, map, switchMap } from 'rxjs/operators';

import {
  Auction as AliasAuction,
  AuctionAliasesGQL,
} from '../modules/graphql/service/graphql-auto-main-service';
import { Auction, AuctionsGQL } from '../modules/graphql/service/graphql-directory-service';

@Injectable()
export class AuctionService {
  public readonly auctions$: Observable<Auction[]>;
  public readonly auctionAliases$: Observable<AliasAuction[]>;

  constructor(
    private queryHandlingService: QueryHandlingService,
    private auctionsGQL: AuctionsGQL,
    private akebonoAuctionsGQL: AuctionAliasesGQL,
  ) {
    this.auctions$ = this.queryHandlingService
      .fetch(this.auctionsGQL, {}, 'cache-first')
      .data.pipe(map((data) => data.auctions));

    this.auctionAliases$ = this.queryHandlingService
      .fetch(this.akebonoAuctionsGQL, {}, 'cache-first')
      .data.pipe(map((data) => data.auctions));
  }

  public getByName(auctionName: string): Observable<Auction | undefined> {
    return combineLatest([this.auctions$, this.auctionAliases$]).pipe(
      switchMap(([ctAuctions, aliasAuctions]) =>
        from(ctAuctions).pipe(
          find((auction) => {
            const aliasAuction = aliasAuctions.find(
              (aa) =>
                aa.title == auctionName ||
                aa.auctionAliases.find((aaa) => aaa.title == auctionName),
            );
            return String(auction.id) === aliasAuction?.cartrackAuctionId;
          }),
        ),
      ),
    );
  }
}
