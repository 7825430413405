import { MutationHandlingServiceV2 } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  toArray,
} from 'rxjs/operators';
import { AutoLotView } from 'src/app/const';

import {
  LotIconEnum,
  LotSource,
  LotTypeEnum,
  LotUpdateGQL,
  UserLotQuery,
} from '../../graphql/service/graphql-auto-main-service';

type IconGroup = {
  icon: LotIconEnum;
  enabled: boolean;
};

@Component({
  selector: 'app-lot-icons',
  templateUrl: './lot-icons.component.html',
  styleUrls: ['./lot-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotIconsComponent implements OnInit, OnDestroy {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() lotIcons?: LotIconEnum[];
  @Input() debounceTime = 1500;
  @Input() currentUser: UserLotQuery['currentUser'];
  @Input() changeManagerState: BehaviorSubject<boolean>;

  form: FormGroup;
  allIcons = Object.values(LotIconEnum);
  enabledIcons = new Set<LotIconEnum>();
  invisibleIcons = new Set<LotIconEnum>([LotIconEnum.NotLeftDrive]);
  guideIcons = new Set<LotIconEnum>(Object.values(LotIconEnum));

  isGuide$ = of(false);
  isManager = false;
  loading$ = new BehaviorSubject(false);

  private readonly destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private mhs: MutationHandlingServiceV2,
    private lotUpdateGQL: LotUpdateGQL,
  ) {
    this.form = this.fb.group({
      icons: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.enabledIcons = new Set<LotIconEnum>(this.lotIcons ?? []);
    this.isManager = this.currentUser.isAuctioneer || this.currentUser.isTranslator;
    this.isGuide$ = this.changeManagerState?.asObservable();

    this.allIcons
      .map((icon) =>
        this.fb.group({
          icon: this.fb.control(icon, Validators.required),
          enabled: this.fb.control(this.enabledIcons.has(icon), Validators.required),
        }),
      )
      .forEach((group) => this.icons.push(group));

    this.icons.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        tap(() => this.loading$.next(true)),
        switchMap((groups) => this.groupsToIcons(groups)),
        switchMap((icons) => this.updateLotIcons(icons)),
        switchMap((icons) => this.iconsToGroups(icons)),
        tap(() => this.loading$.next(false)),
        takeUntil(this.destroy$),
      )
      .subscribe((groups) => this.icons.patchValue(groups, { emitEvent: false }));
  }

  private groupsToIcons(groups: IconGroup[]): Observable<LotIconEnum[]> {
    return from<IconGroup[]>(groups).pipe(
      filter((group) => group.enabled),
      map((group) => group.icon),
      toArray(),
    );
  }

  private iconsToGroups(icons: LotIconEnum[]): Observable<IconGroup[]> {
    return from(this.allIcons).pipe(
      map((enumIcon) => ({
        icon: enumIcon,
        enabled: icons.includes(enumIcon),
      })),
      toArray(),
    );
  }

  get icons(): FormArray {
    return this.form.controls.icons as FormArray;
  }

  private updateLotIcons(icons: LotIconEnum[]): Observable<LotIconEnum[]> {
    return this.mhs
      .mutate(this.lotUpdateGQL, {
        input: {
          lotId: this.lot.id,
          lotSource: this.lotSource,
          lotType: this.lotType,
          icons,
        },
      })
      .data.pipe(
        map((result) => Array.from(result.lotUpdate?.lot?.icons ?? [])),
        catchError(() => of([])),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
