import { Currency } from '@akebono/core';
import { UserFilterPreset } from '@akebono/core/lib/graphql/service/graphql-currency-exchange-service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QueryRef } from 'apollo-angular';
import { Config } from 'driver.js';
import { DateTime } from 'luxon';
import { Observable, Subscription } from 'rxjs';
import { DateRange, getDaysRange, simpleNameSort, toLocalIsoString } from 'src/app/const';
import {
  LotsListFiltersGQL,
  LotsListFiltersQuery,
  LotsListFiltersQueryVariables,
} from 'src/app/modules/graphql/service/graphql-cars-default-service';

import { DriverService } from '../../../driver/driver.service';

@Component({
  selector: 'app-auto-lots-filter',
  templateUrl: './auto-lots-filter.component.html',
  styleUrls: ['./auto-lots-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoLotsFilterComponent {
  data: any = {
    total: 0,
    nodes: [],
  };
  filtersData: LotsListFiltersQuery['lotsFiltersData'];
  pageSize = 20;
  page = 1;
  loadingFilters = true;
  loadingFrames = true;
  extendedSearchVisible = false;
  total = 0;

  years: number[] = [];
  minYear = 1985;

  form: FormGroup;

  modelsList: any[];
  otherCompaniesList: any[];
  mainCompaniesList: any[];
  mainCompaniesNames = [
    'TOYOTA',
    'NISSAN',
    'MAZDA',
    'MITSUBISHI',
    'HONDA',
    'SUZUKI',
    'SUBARU',
    'ISUZU',
    'DAIHATSU',
    'MITSUOKA',
    'LEXUS',
  ];
  auctRefOptions: any[];
  trucks: any[];
  auctions: any[];
  isYearFiltered = false;
  isMileageFiltered = false;
  isEngineVolumeFiltered = false;
  isProductionDateFiltered = false;
  isActiveFilters = true;
  lastModelId: string;
  filterPresetDeletings$: { [key: string]: Observable<boolean> } = {};
  weekdayTranslationKeys = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  inclusionFilterFields = {
    frame: 'modelTypeEn',
    score: 'scoresEn',
    auction: 'auctRef',
    color: 'colorEn',
    transmission: 'transmissionEn',
    date: 'date',
    result: 'result',
  };
  lastDay: Date;
  dateRange: DateRange[] = [];
  currentCurrency: Currency;
  dumpVersion: string;
  outdated = false;
  lotsListFiltersQueryRef: QueryRef<LotsListFiltersQuery, LotsListFiltersQueryVariables>;
  sub = new Subscription();

  presetContent: any = null;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private driverService: DriverService,
    private listLotsFiltersGQL: LotsListFiltersGQL,
  ) {
    for (let year = new Date().getFullYear(); year >= this.minYear; year--) {
      this.years.push(year);
    }

    const formState = this.getFilterFormState(this.route.snapshot.queryParams);
    console.log(formState);
    this.form = this.fb.group({
      company: this.fb.control(formState.company),
      model: this.fb.control(formState.model),
      truck: this.fb.control(formState.truck),
      bid: this.fb.control(formState.bid),
      yearFrom: this.fb.control(formState.yearFrom),
      yearTo: this.fb.control(formState.yearTo),
      mileageFrom: this.fb.control(formState.mileageFrom),
      mileageTo: this.fb.control(formState.mileageTo),
      engineVolumeFrom: this.fb.control(formState.engineVolumeFrom),
      engineVolumeTo: this.fb.control(formState.engineVolumeTo),
      inclusionFrame: this.fb.control([formState.inclusionFrame].flat()),
      inclusionScore: this.fb.control(formState.inclusionScore),
      inclusionAuction: this.fb.control([formState.inclusionAuction].flat()),
      inclusionColor: this.fb.control(formState.inclusionColor),
      inclusionStatus: this.fb.control(formState.inclusionStatus),
      inclusionTransmission: this.fb.control(formState.inclusionTransmission),
      productionDate: this.fb.control(formState.productionDate),
      datetime: this.fb.control(formState.datetime),
      selectedDays: this.fb.control(formState.selectedDays),
      modelGrade: this.fb.control(formState.modelGrade),
      drive: this.fb.control(formState.drive),
    });
    this.form.get('company').valueChanges.subscribe(this.onSelectedCompanyChange.bind(this));
    this.form.get('model').valueChanges.subscribe(this.onSelectedModelChange.bind(this));

    this.presetContent = this.form.value;
    this.form.valueChanges.subscribe((content) => (this.presetContent = content));

    this.initDates();

    this.route.queryParams.subscribe(async (params) => {
      // if (this.loadingFilters || params.model != this.lastModelId) {
      await this.fetchFilterData(params);
      // }
    });
  }

  async fetchFilterData(params: Params) {
    this.loadingFrames = true;
    this.lastModelId = params.model;
    const hasModelId = !!(params.model && params.model !== 'any');
    const filter = this.getQueryFilter(params);
    const result = await this.listLotsFiltersGQL
      .fetch(
        {
          withModel: hasModelId,
          filter,
        },
        {
          fetchPolicy: 'network-only',
        },
      )
      .toPromise();

    this.checkOutdated(result.data.dumpVersion);

    this.auctRefOptions = result.data.lotsFiltersData.auctRefOptions
      .map((op) => op.autoAuction)
      .sort(simpleNameSort);
    this.otherCompaniesList = result.data.autoCompanies.nodes
      .filter((c) => !this.mainCompaniesNames.includes(c.name.toLocaleUpperCase()))
      .sort(simpleNameSort);
    this.mainCompaniesList = result.data.autoCompanies.nodes
      .filter((c) => this.mainCompaniesNames.includes(c.name.toLocaleUpperCase()))
      .sort(simpleNameSort)
      .reverse();

    if (!this.auctions) {
      this.auctions = result.data.autoAuctions.nodes;
    }
    if (!this.trucks) {
      this.trucks = result.data.autoTrucks.nodes;
    }
    if (!this.modelsList) {
      this.modelsList = result.data.autoModels.nodes;
    }

    this.filtersData = result.data.lotsFiltersData;
    this.cdr.markForCheck();
    this.loadingFilters = false;
    this.loadingFrames = false;
  }

  getQueryFilter(params: any) {
    const inclusionFilter = [];
    Object.keys(this.inclusionFilterFields).forEach((key) => {
      if (params[key] && params[key].length > 0) {
        inclusionFilter.push({
          fieldName: this.inclusionFilterFields[key],
          eq: [].concat(params[key]),
        });
      }
    });
    const mFrom = Number(params.mileageFrom);
    const mTo = Number(params.mileageTo);
    return {
      companyRef: {
        eq: params.company,
      },
      modelNameRef: {
        eq: params.model,
      },
      truckRef: {
        eq: params.truck,
      },
      year: {
        gte: Number(params.yearFrom),
        lte: Number(params.yearTo),
      },
      mileage: {
        gte: mFrom ? mFrom : null,
        lte: mTo ? mTo : null,
      },
      engineVolume: {
        gte: Number(params.engineVolumeFrom),
        lte: Number(params.engineVolumeTo),
      },
      productionDate: {
        gte: params.productionDateFrom,
        lte: params.productionDateTo,
      },
      bid: {
        eq: params.bid,
      },
      inclusion:
        inclusionFilter.length > 0
          ? {
              columns: inclusionFilter,
            }
          : undefined,
      datetime: {
        gt: params.datetime,
      },
      modelGradeEn: {
        like: params.modelGrade,
      },
      leftDrive: {
        eq: params.drive ? params.drive == 'left' : undefined,
      },
    };
  }

  onSelectedCompanyChange(company: string) {
    this.form.get('bid').setValue(null);
    this.form.get('inclusionScore').setValue(['any']);
    this.form.get('inclusionFrame').setValue(['any']);
    this.form.get('inclusionColor').setValue(['any']);
    this.form.get('inclusionTransmission').setValue(['any']);
    this.form.get('inclusionAuction').setValue(['any']);
    // this.form.get('yearFrom').setValue(null);
    // this.form.get('yearTo').setValue(null);
    this.router.navigate(['auto'], {
      queryParams: {
        page: 1,
        company: company,
        model: 'any',
        frame: null,
        bid: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  onSelectedModelChange(model: string) {
    this.form.get('bid').setValue(null);
    this.form.get('inclusionScore').setValue(['any']);
    this.form.get('inclusionFrame').setValue(['any']);
    this.form.get('inclusionColor').setValue(['any']);
    this.form.get('inclusionTransmission').setValue(['any']);
    this.form.get('inclusionAuction').setValue(['any']);
    // this.form.get('yearFrom').setValue(null);
    // this.form.get('yearTo').setValue(null);
    this.router.navigate(['auto'], {
      queryParams: {
        page: 1,
        model: model,
        frame: null,
        color: null,
        auction: null,
        score: null,
        bid: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  private toUTCDate(date: Date) {
    date.setUTCDate(date?.getDate());
    return date;
  }

  filter() {
    const value = this.form.value;
    const bidSet = value.bid && value.bid !== '№ лота';
    this.router.navigate(['auto'], {
      queryParams: {
        r: Math.floor(Math.random() * 100),
        page: 1,
        company: value.company,
        model: value.model,
        truck: value.truck,
        bid: bidSet ? value.bid : undefined,
        yearFrom: value.yearFrom ? value.yearFrom : undefined,
        yearTo: value.yearTo ? value.yearTo : undefined,
        mileageFrom: value.mileageFrom ? value.mileageFrom : undefined,
        mileageTo: value.mileageTo ? value.mileageTo : undefined,
        engineVolumeFrom: value.engineVolumeFrom ? value.engineVolumeFrom : undefined,
        engineVolumeTo: value.engineVolumeTo ? value.engineVolumeTo : undefined,
        modelGrade: value.modelGrade ? value.modelGrade : undefined,
        drive: value.drive ? value.drive : undefined,
        productionDateFrom: value.productionDate[0]
          ? this.toUTCDate(value.productionDate[0]).toISOString()
          : undefined,
        productionDateTo: value.productionDate[1]
          ? this.toUTCDate(value.productionDate[1]).toISOString()
          : undefined,
        frame:
          typeof value.inclusionFrame !== 'string' && value.inclusionFrame.length > 0
            ? value.inclusionFrame.filter((v) => v !== 'any')
            : undefined,
        score:
          typeof value.inclusionScore !== 'string' && value.inclusionScore.length > 0
            ? value.inclusionScore.filter((v) => v !== 'any')
            : undefined,
        auction:
          typeof value.inclusionAuction !== 'string' && value.inclusionAuction.length > 0
            ? value.inclusionAuction.filter((v) => v !== 'any')
            : undefined,
        color:
          typeof value.inclusionColor !== 'string' && value.inclusionColor.length > 0
            ? value.inclusionColor.filter((v) => v !== 'any')
            : undefined,
        status: value.inclusionStatus.length > 0 ? value.inclusionStatus : undefined,
        transmission:
          typeof value.inclusionTransmission !== 'string' && value.inclusionTransmission.length > 0
            ? value.inclusionTransmission.filter((v) => v !== 'any')
            : undefined,
        date: value.selectedDays.length > 0 ? value.selectedDays : undefined,
        datetime: value.datetime
          ? DateTime.local().setZone('Asia/Tokyo').plus({ minutes: 15 }).toISO()
          : undefined,
      },
      queryParamsHandling: 'merge',
    });
  }

  initDates() {
    this.lastDay = new Date();
    this.lastDay.setDate(this.lastDay.getDate() + 7);
    if (this.dateRange.length === 0) {
      const dates: Date[] = getDaysRange(new Date(), new Date(this.lastDay));
      dates.forEach((item) => {
        this.dateRange.push({
          date: item,
          checked: this.form.value.selectedDays.indexOf(toLocalIsoString(this.getDate(item))) > -1,
        });
      });
      this.dateRange.sort((a, b) => {
        if (a.date == b.date) {
          return 0;
        } else if ((a.date.getDay() + 6) % 7 > (b.date.getDay() + 6) % 7) {
          return 1;
        } else {
          return -1;
        }
      });
    } else {
      this.dateRange.forEach((item) => {
        item.checked =
          this.form.value.selectedDays.indexOf(toLocalIsoString(this.getDate(item.date))) > -1;
      });
    }
  }

  clearValue(formControlName: string): void {
    this.form.get(formControlName).setValue(null);
  }

  resetFilter() {
    this.form.reset(this.getFilterFormState({}));
    this.initDates();
    this.router.navigate(['auto']);
    this.outdated = false;
    return false;
  }

  selectAuctionDate(event, date: DateRange) {
    const dateJapan = toLocalIsoString(
      this.getDate(date.date.toLocaleString('en-US', { timeZone: 'Asia/Tokyo' })),
    );
    date.checked = event;
    if (event) {
      this.form.controls.selectedDays.setValue(
        this.form.controls.selectedDays.value.concat(dateJapan),
      );
    } else {
      const day = this.form.controls.selectedDays.value.indexOf(dateJapan);
      if (day > -1) {
        const days = this.form.controls.selectedDays.value;
        days.splice(day, 1);
        this.form.controls.selectedDays.setValue(days);
      }
    }
    this.filter();
  }

  private checkOutdated(dumpVersion) {
    if (!this.dumpVersion) {
      this.dumpVersion = dumpVersion;
    } else if (this.dumpVersion !== dumpVersion) {
      this.outdated = true;
    }
  }

  getDate(string = null): Date {
    if (string) {
      return new Date(string);
    }
    return new Date();
  }

  getFilterFormState(params: any) {
    this.isYearFiltered = (params.yearFrom || params.yearTo) as boolean;
    this.isMileageFiltered = (params.mileageFrom || params.mileageTo) as boolean;
    this.isEngineVolumeFiltered = (params.engineVolumeFrom || params.engineVolumeTo) as boolean;
    const productionDate = [];
    if (params.productionDateFrom) {
      productionDate[0] = params.productionDateFrom;
    }
    if (params.productionDateTo) {
      productionDate[1] = params.productionDateTo;
    }
    return {
      company: params.company || 'any',
      model: params.model || 'any',
      bid: params.bid || '№ лота',
      yearFrom: params.yearFrom ? Number(params.yearFrom) : null,
      yearTo: params.yearTo ? Number(params.yearTo) : null,
      mileageFrom: params.mileageFrom || null,
      mileageTo: params.mileageTo || null,
      engineVolumeFrom: params.engineVolumeFrom || null,
      engineVolumeTo: params.engineVolumeTo || null,
      truck: params.truck ? [].concat(params.truck) : undefined,
      productionDate,
      inclusionFrame: params.frame ? [].concat(params.frame) : ['any'],
      inclusionAuction: params.auction ? [].concat(params.auction) : ['any'],
      inclusionScore: params.score ? [].concat(params.score) : ['any'],
      inclusionColor: params.color ? [].concat(params.color) : ['any'],
      inclusionStatus: params.status ? [].concat(params.status) : [],
      inclusionTransmission: params.transmission ? [].concat(params.transmission) : ['any'],
      datetime: params.datetime || false,
      selectedDays: params.date ? [].concat(params.date) : [],
      modelGrade: params.modelGrade || null,
      drive: params.drive || null,
    };
  }

  valueIncludes(control: AbstractControl, value: any) {
    return typeof control.value.includes == 'function' && control.value.includes(value);
  }

  addValueToControl(control: AbstractControl, value: any) {
    const newVal = control.value.concat(value).filter((v) => v !== 'any');
    control.setValue(newVal);
  }

  removeValueFromControl(control: AbstractControl, value: any) {
    control.setValue(control.value.filter((v: any) => v !== value));
  }

  applyFilterPreset(preset: UserFilterPreset) {
    console.log('patching value');
    this.form.patchValue(preset.content);
    this.initDates();
    this.filter();
  }

  startGuide(event: Event): void {
    if (this.isActiveFilters) {
      event.stopPropagation();
    }

    const config: Config = {
      prevBtnText: this.translate.instant('DRIVER_CONFIG.PREV_BTN'),
      nextBtnText: this.translate.instant('DRIVER_CONFIG.NEXT_BTN'),
      doneBtnText: this.translate.instant('DRIVER_CONFIG.DONE_BTN'),
    };

    this.driverService.setDriverName('auto-lots-filter');
    this.driverService.setConfig(config);
    this.driverService.start();
    this.cdr.markForCheck();
  }
}
