<main nz-row [nzGutter]="[0, 20]">
  <nz-col [nzSpan]="24">
    <nz-table
      #table
      nzSize="small"
      [nzLoading]="loading"
      [nzData]="notificationSettings$ | async"
      [nzShowPagination]="false"
      [nzFrontPagination]="false"
    >
      <thead>
        <tr>
          <th>{{ 'NOTIFICATION_SETTINGS.NAME' | translate }}</th>
          <th *ngFor="let channel of notificationChannels" nzWidth="1px" nzAlign="center">
            {{ channel | titlecase }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let notification of table.data">
          <td>{{ 'NOTIFICATION_SETTINGS.NAMES.' + notification.name | translate }}</td>
          <td *ngFor="let notificationValue of notification.values" nzAlign="center">
            <nz-switch
              [(ngModel)]="notificationValue.value"
              [nzCheckedChildren]="checkedTemplate"
              [nzUnCheckedChildren]="unCheckedTemplate"
              [nzDisabled]="notificationValue.disabled"
              (ngModelChange)="changes$.next(table.data)"
            ></nz-switch>
            <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
            <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-col>
  <nz-col [nzSpan]="24">
    <button nz-button nzType="primary" (click)="openConnectTelegramModal()">
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      {{ 'NOTIFICATION_SETTINGS.CONNECT_TELEGRAM' | translate }}
    </button>
  </nz-col>
</main>
