<button nz-button nzSize="large" (click)="isVisible = true">
  <i nz-icon nzType="history" nzTheme="outline"></i>
</button>

<nz-modal
  [nzWidth]="1000"
  [(nzVisible)]="isVisible"
  [nzTitle]="'LOT_CHANGES.TITLE' | translate"
  [nzContent]="content"
  [nzFooter]="footer"
  (nzOnCancel)="isVisible = false"
>
  <ng-template #content>
    <nz-table
      #table
      nzSize="small"
      [nzBordered]="true"
      nzShowPagination
      nzShowSizeChanger
      [nzPageSize]="20"
      [nzData]="versions$ | async"
      [nzPageSizeOptions]="[10, 20, 30, 40, 50]"
    >
      <thead>
        <tr>
          <th nzWidth="1px">{{ 'LOT_CHANGES.VERSION' | translate }}</th>
          <th nzWidth="1px">{{ 'LOT_CHANGES.DATE' | translate }}</th>
          <th nzWidth="1px">{{ 'LOT_CHANGES.AUTHOR' | translate }}</th>
          <th>{{ 'LOT_CHANGES.DESCRIPTION' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let v of table.data">
          <td nzAlign="center">{{ v.versionNumber }}</td>
          <td class="no-wrap" nzAlign="center">
            {{ v.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}
          </td>
          <td class="no-wrap">
            <a
              *ngIf="v.author"
              target="__blank"
              [href]="managerDomain + '/akebono-auctioneer/users/edit/' + v.author.id"
            >
              {{ v.author.fullName }}
              ({{ v.author.username }})
            </a>
            <span *ngIf="!v.author">System</span>
          </td>

          <td *ngIf="!rawView">
            <section *ngIf="v.versionNumber === 1">
              {{ 'LOT_CHANGES.CHANGES.CREATED' | translate }}
            </section>
            <section *ngIf="v.versionNumber !== 1">
              <ng-container *ngFor="let change of v.changeset$ | async">
                <div [ngSwitch]="change.key">
                  <span *ngSwitchCase="'akebono_lot_translations_count'">
                    {{ 'LOT_CHANGES.CHANGES.TRANSLATIONS_COUNT' | translate }}
                    <nz-tag>{{ change.value }}</nz-tag>
                  </span>
                  <span *ngSwitchCase="'icons'">
                    {{ 'LOT_CHANGES.CHANGES.ICONS' | translate }}
                    <nz-tag>[{{ extractIcons(change.value) }}]</nz-tag>
                  </span>
                  <span *ngSwitchCase="'vin'">
                    {{ 'LOT_CHANGES.CHANGES.VIN' | translate }}
                    <nz-tag>{{ change.value }}</nz-tag>
                  </span>
                  <span *ngSwitchCase="'production_date'">
                    {{ 'LOT_CHANGES.CHANGES.PRODUCTION_DATE' | translate }}
                    <nz-tag>{{ change.value | date: 'yyyy-MM-dd' }}</nz-tag>
                  </span>
                  <span *ngSwitchCase="'photos_fetched'">
                    {{ 'LOT_CHANGES.CHANGES.PHOTOS_FETCHED' | translate }}
                  </span>
                  <span *ngSwitchCase="'status'">
                    {{ 'LOT_CHANGES.CHANGES.STATUS' | translate }}
                    <nz-tag>
                      {{ 'LOT_STATUSES.' + lotStatuses[change.value] | uppercase | translate }}
                    </nz-tag>
                  </span>
                  <span *ngSwitchCase="'images'">
                    {{ 'LOT_CHANGES.CHANGES.IMAGES' | translate }}
                    <nz-tag>{{ extractImages(change.value).length }}</nz-tag>
                  </span>
                  <span *ngSwitchCase="'thumbnails'">
                    {{ 'LOT_CHANGES.CHANGES.THUMBNAILS' | translate }}
                    <nz-tag>{{ extractImages(change.value).length }}</nz-tag>
                  </span>
                  <span *ngSwitchCase="'updated_at'">
                    {{ 'LOT_CHANGES.CHANGES.UPDATED' | translate }}
                  </span>
                  <span *ngSwitchCase="'start_price_num'">
                    {{ 'LOT_CHANGES.CHANGES.START_PRICE' | translate }}
                    <nz-tag>{{ change.value ?? 0 }}¥</nz-tag>
                  </span>
                  <span *ngSwitchCase="'sold_price'">
                    {{ 'LOT_CHANGES.CHANGES.SOLD_PRICE' | translate }}
                    <nz-tag>{{ change.value ?? 0 }}¥</nz-tag>
                  </span>
                  <span *ngSwitchCase="'negotiations_price'">
                    {{ 'LOT_CHANGES.CHANGES.NEGOTIATIONS_PRICE' | translate }}
                    <nz-tag>{{ change.value ?? 0 }}¥</nz-tag>
                  </span>
                  <span *ngSwitchCase="'auction_datetime'">
                    {{ 'LOT_CHANGES.CHANGES.AUCTION_DATETIME' | translate }}
                    <nz-tag>{{ change.value | date: 'yyyy-MM-dd HH:mm' }}</nz-tag>
                  </span>
                  <span *ngSwitchDefault>
                    {{ change.key }}:
                    <nz-tag>{{ change.value }}</nz-tag>
                  </span>
                </div>
              </ng-container>
            </section>
          </td>

          <td *ngIf="rawView">
            <ngx-json-viewer [json]="v.changeset" [expanded]="false"></ngx-json-viewer>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </ng-template>

  <ng-template #footer>
    <nz-row nzJustify="space-between">
      <nz-col>
        <nz-switch
          [(ngModel)]="rawView"
          nzCheckedChildren="raw"
          nzUnCheckedChildren="normal"
        ></nz-switch>
      </nz-col>
      <nz-col>
        <button nz-button (click)="isVisible = false">
          {{ 'CANCEL' | translate }}
        </button>
      </nz-col>
    </nz-row>
  </ng-template>
</nz-modal>
