import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  PreferredLang,
  TranslationMediaTypeEnum,
  UserTranslationSettingsFragment,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';

import { AudioTranslationSpeedService } from '../../../lot-translations/services/audio-translation-speed.service';
import { SettingsChanges } from '../../types/settings-changes.type';

@Component({
  selector: 'app-translation-settings',
  templateUrl: './translation-settings.component.html',
  styleUrls: ['./translation-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationSettingsComponent implements OnInit, OnDestroy {
  @Input() loading = false;
  @Output() settingsChange = new EventEmitter<SettingsChanges['translation']>();

  form: FormGroup;

  canChangeLanguage = true;
  currentPreferredLang?: PreferredLang;

  preferredLangs = Object.values(PreferredLang);
  translationMediaTypes = Object.values(TranslationMediaTypeEnum);

  audioTranslationSpeeds = [];

  private destroy$ = new Subject<void>();

  @Input()
  set bidSettings(settings: UserTranslationSettingsFragment) {
    this.currentPreferredLang = settings?.preferredLang;
    this.form.patchValue({
      preferredLang: this.currentPreferredLang,
      defaultTranslationMediaType: settings?.defaultTranslationMediaType,
    });

    if (settings && settings.isOtherCountries) {
      this.canChangeLanguage = settings.preferredLang === PreferredLang.Ru;
    } else {
      this.canChangeLanguage = true;
    }
  }

  constructor(
    private fb: FormBuilder,
    private audioTranslationSpeedService: AudioTranslationSpeedService,
  ) {
    this.form = this.fb.group({
      preferredLang: this.fb.control(null),
      defaultTranslationMediaType: this.fb.control(null),
      defaultAudioTranslationSpeed: this.fb.control(1),
    });

    for (let speed = 0.25; speed <= 3; speed += 0.25) {
      this.audioTranslationSpeeds.push(speed);
    }
  }

  canSetLanguage(lang: PreferredLang): boolean {
    return this.canChangeLanguage || this.currentPreferredLang == lang;
  }

  ngOnInit(): void {
    this.form.patchValue({
      defaultAudioTranslationSpeed: this.audioTranslationSpeedService.speed,
    });

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.settingsChange.emit(value));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
