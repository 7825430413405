import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { LotBidAmountModule } from '../lot-bid-amount/lot-bid-amount.module';
import { ColorPoolFactoryService } from './color-pool/color-pool-factory.service';
import { LotBidsComponent } from './lot-bids.component';

@NgModule({
  declarations: [LotBidsComponent],
  imports: [
    CommonModule,
    BrowserModule,
    TranslateModule,
    AkebonoCoreModule,
    NzTableModule,
    NzButtonModule,
    NzModalModule,
    NzTagModule,
    NzIconModule,
    NzToolTipModule,
    LotBidAmountModule,
  ],
  providers: [ColorPoolFactoryService],
  exports: [LotBidsComponent],
})
export class LotBidsModule {}
