import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AutoLotView } from 'src/app/const';
import { LotTypeEnum } from 'src/app/modules/graphql/service/graphql-auto-main-service';

import { PurchaseReportFormModalService } from '../../services/purchase-report-form-modal.service';

@Component({
  selector: 'app-purchase-report-button',
  templateUrl: './purchase-report-button.component.html',
  styleUrls: ['./purchase-report-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseReportButtonComponent {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;

  constructor(private readonly purchaseReportFormModalService: PurchaseReportFormModalService) {}

  openPurchaseReportFormModal(): void {
    this.purchaseReportFormModalService.openPurchaseReportFromModal(this.lot, this.lotType);
  }
}
