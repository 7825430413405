import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { LightboxModule } from 'ngx-lightbox';
import { SwiperModule } from 'swiper/angular';

import { LotCarouselComponent } from './lot-carousel.component';
import { AuctionsTipModalComponent } from './components/auctions-tip-modal/auctions-tip-modal.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { UssAuctionComponent } from './components/auctions-tip-modal/components/uss-auction/uss-auction.component';
import { TaaAuctionComponent } from './components/auctions-tip-modal/components/taa-auction/taa-auction.component';
import { CaaAuctionComponent } from './components/auctions-tip-modal/components/caa-auction/caa-auction.component';
import { JuAuctionComponent } from './components/auctions-tip-modal/components/ju-auction/ju-auction.component';
import { LaaAuctionComponent } from './components/auctions-tip-modal/components/laa-auction/laa-auction.component';
import { AraiAuctionComponent } from './components/auctions-tip-modal/components/arai-auction/arai-auction.component';
import { HeroAuctionComponent } from './components/auctions-tip-modal/components/hero-auction/hero-auction.component';
import { IaaOsakaAuctionComponent } from './components/auctions-tip-modal/components/iaa-osaka-auction/iaa-osaka-auction.component';
import { BayaucAuctionComponent } from './components/auctions-tip-modal/components/bayauc-auction/bayauc-auction.component';
import { MiriveAuctionComponent } from './components/auctions-tip-modal/components/mirive-auction/mirive-auction.component';

@NgModule({
  declarations: [
    LotCarouselComponent,
    AuctionsTipModalComponent,
    UssAuctionComponent,
    TaaAuctionComponent,
    CaaAuctionComponent,
    JuAuctionComponent,
    LaaAuctionComponent,
    AraiAuctionComponent,
    HeroAuctionComponent,
    IaaOsakaAuctionComponent,
    BayaucAuctionComponent,
    MiriveAuctionComponent,
  ],
  exports: [LotCarouselComponent],
  imports: [
    CommonModule,
    SwiperModule,
    NzIconModule,
    LightboxModule,
    NzButtonModule,
    NzPopconfirmModule,
    NzToolTipModule,
    NzSpinModule,
    TranslateModule,
    AkebonoCoreModule,
    NzTabsModule,
  ],
})
export class LotCarouselModule {}
