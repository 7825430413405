import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { from, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, toArray } from 'rxjs/operators';

import {
  NotificationSettingChannel,
  NotificationSettingName,
  UserNotificationSettingsFragment,
} from '../../../../../modules/graphql/service/graphql-auto-main-service';
import { ConnectTelegramService } from '../../../connect-telegram/connect-telegram.service';
import { NotificationSetting } from '../../types/notification-setting.type';
import { SettingsChanges } from '../../types/settings-changes.type';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationSettingsComponent implements OnInit {
  @Input() loading = false;
  @Output() settingsChange = new EventEmitter<SettingsChanges['notification']>();

  notificationSettings$: Observable<NotificationSetting[]> = of([]);
  notificationChannels: NotificationSettingChannel[] = [
    NotificationSettingChannel.Email,
    NotificationSettingChannel.Telegram,
  ];
  changes$ = new Subject<any>();

  private disabledNotifications = [{ channel: 'sms', name: 'AkebonoBidTranslationCompleted' }];
  private subscription = Subscription.EMPTY;

  @Input()
  set notificationSettings(settings: UserNotificationSettingsFragment) {
    this.notificationSettings$ = of(settings).pipe(
      filter((data) => data?.notificationSettings),
      map((data) => data.notificationSettings),
      map((settings) =>
        Object.values(NotificationSettingName).map((settingName) => ({
          name: settingName,
          values: this.notificationChannels.sort().map((settingChannel) => ({
            channel: settingChannel,
            value: this.isDisabled(settingChannel, settingName)
              ? false
              : settings[settingChannel]?.[settingName] ?? true,
            default: settings[settingChannel]?.[settingName] ?? true,
            disabled: this.isDisabled(settingChannel, settingName),
          })),
        })),
      ),
    );
  }

  constructor(private connectTelegramService: ConnectTelegramService) {}

  ngOnInit(): void {
    this.subscription = this.changes$
      .pipe(
        switchMap((settings: NotificationSetting[]) =>
          from(settings).pipe(
            filter((setting) => setting.values.some((v) => v.value !== v.default)),
            map((setting) =>
              setting.values
                .filter((v) => v.value !== v.default)
                .map((v) => ({
                  name: setting.name,
                  value: v.value,
                  channel: v.channel,
                })),
            ),
            toArray(),
          ),
        ),
        map((inputs) => inputs.flat()),
      )
      .subscribe((inputs) => this.settingsChange.emit(inputs));
  }

  private isDisabled(channel: NotificationSettingChannel, name: string): boolean {
    return Boolean(
      this.disabledNotifications.find((n) => n.channel === channel && n.name === name),
    );
  }

  openConnectTelegramModal(): void {
    this.connectTelegramService.openModal();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
