<button nz-button nzSize="large" (click)="modalVisible = true">
  <i nz-icon nzType="ordered-list" nzTheme="outline"></i>
</button>

<nz-modal
  [(nzVisible)]="modalVisible"
  [nzTitle]="'BIDS' | translate"
  [nzFooter]="null"
  [nzWidth]="'980px'"
  (nzOnCancel)="modalVisible = false"
>
  <section *nzModalContent>
    <nz-table
      #table
      nzBordered
      nzSize="small"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzData]="bids"
      [nzTitle]="
        'LOT_BIDS_TABLE_TITLE' | translate: { bidsTotal: bids.length, usersTotal: usersTotal }
      "
    >
      <thead>
        <tr>
          <th [nzSortFn]="bidUserFullNameSort" [nzSortPriority]="true">{{ 'USER' | translate }}</th>
          <th
            *akbCheck="'cars.lot-user-bids-amount'"
            [nzSortPriority]="true"
            [nzSortFn]="bidFullAmountSort"
            nzWidth="100px"
          >
            {{ 'AMOUNT' | translate }}
          </th>
          <th nzWidth="100px">{{ 'GROUP' | translate }}</th>
          <th nzWidth="1px">{{ 'STATUS' | translate }}</th>
          <th nzWidth="1px">{{ 'CONDITION' | translate }}</th>
          <th
            nzWidth="198px"
            [nzSortPriority]="true"
            [nzSortOrder]="'descend'"
            [nzSortFn]="bidCreatedAtSort"
          >
            {{ 'BIDS_DATE' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let bid of table.data">
          <td>
            <span class="user-color" [style.background-color]="userColorsMap[bid.user.id]"></span>
            <a [href]="getUserUrl(bid.user)" target="_blank">
              {{ bid.user.fullName }}
            </a>
            <i
              *ngIf="bid.user.hasActiveBestBidViewServiceSubscription"
              nz-icon
              nz-tooltip
              nzType="eye"
              nzTheme="outline"
              [nzTooltipTitle]="'SUBSCRIBE_TO_BEST_BID.HAS_ACTIVE_SUBSCRIPTION' | translate"
            ></i>
          </td>
          <td *akbCheck="'cars.lot-user-bids-amount'">
            <app-lot-bid-amount
              [bid]="bid"
              [renderColon]="false"
              commissionPrefix="+"
            ></app-lot-bid-amount>
          </td>
          <td>
            <span *ngIf="bid.bidGroup"
              >{{ bid.bidGroup.letter }} - {{ bid.bidGroup.quantity }}</span
            >
            <span *ngIf="!bid.bidGroup">
              {{ 'LOT_BIDDING_MODAL.NO_GROUP' | translate }}
            </span>
          </td>
          <td>
            <nz-tag [nzColor]="tagColorMap[bid.status]">
              {{ bid.status | uppercase | translate }}
            </nz-tag>
          </td>
          <td>
            <span *ngIf="bid.needPriorityPass" title="Priority pass">
              <i nz-icon nzType="crown" nzTheme="fill" style="color: #d48806"></i>
            </span>
            {{ bid.bidCondition.title }}
          </td>
          <td>
            {{ bid.createdAt | date: 'yyyy-MM-dd HH:mm:ss zz':'+0900' }}
          </td>
        </tr>
      </tbody>
    </nz-table>
  </section>
</nz-modal>
