<nz-spin nzSize="large" [nzSpinning]="loading$ | async">
  <header>
    <app-lot-last-visit-date [lotId]="(data$ | async)?.lot?.id"></app-lot-last-visit-date>
    <nz-result
      *ngIf="lotNotFound$ | async"
      nzStatus="404"
      [nzTitle]="'LOT_NOT_FOUND' | translate"
    ></nz-result>
    <nz-row *ngIf="(data$ | async)?.lot as lot" nzJustify="space-between">
      <app-lot-title-full
        nz-col
        nzFlex="auto"
        nzXs="20"
        [lot]="lot"
        appDriverStep
        [overrideStepIndex]="0"
        [stepTitle]="'AUTO_LOT.GUIDE.TITLE_BLOCK.TITLE' | translate"
        [stepDescription]="'AUTO_LOT.GUIDE.TITLE_BLOCK.DESCR' | translate"
        [stepSide]="'left'"
        [stepAlign]="'start'"
        [popoverClass]="'auto-lot'"
      ></app-lot-title-full>
      <button
        nz-col
        nzFlex="32px"
        nzXs="4"
        class="guide-btn-auto-lot"
        nz-tooltip
        nzTooltipTitle="Подсказка"
        nzTooltipPlacement="right"
        nz-button
        nzType="default"
        nzShape="circle"
        appDriverGuide
        (click)="startDriver()"
      >
        <span nz-icon nzType="question" nzTheme="outline"></span>
      </button>
    </nz-row>
  </header>

  <ng-container *ngIf="data$ | async as data">
    <div *ngIf="data?.lot && data?.isSanctioned">
      <nz-result
        nzStatus="warning"
        [nzTitle]="'SANCTIONED_LOT_NOT_ALLOWED' | translate"
      ></nz-result>
    </div>
    <main *ngIf="data?.lot && !data?.isSanctioned" nz-row nzGutter="16">
      <aside nz-col nzXs="24" nzLg="10">
        <section
          appDriverStep
          [overrideStepIndex]="1"
          [stepTitle]="'AUTO_LOT.GUIDE.PHOTO_GALLERY.TITLE' | translate"
          [stepDescription]="'AUTO_LOT.GUIDE.PHOTO_GALLERY.DESCR' | translate"
          [stepSide]="'left'"
          [stepAlign]="'start'"
          [popoverClass]="'auto-lot'"
        >
          <app-lot-carousel
            [lot]="data.lot"
            [lotType]="data.lotType"
            [lotSource]="data.lotSource"
            [currentUser]="data.currentUser"
            [isUsingSavedImages]="data.isUsingSavedImages"
            [(activeSlide)]="activeSlide"
            (imgDelete)="refetch$.next()"
          ></app-lot-carousel>
        </section>

        <section>
          <app-alerts-list-auto
            [target]="'lot_page'"
            [auction]="data.lot.auctionName"
            [lotCode]="data.lot.bid"
          ></app-alerts-list-auto>
        </section>
      </aside>

      <aside nz-col nzXs="24" nzLg="14">
        <section
          appDriverStep
          [overrideStepIndex]="2"
          [stepTitle]="'AUTO_LOT.GUIDE.ADDITIONAL_TAGS.TITLE' | translate"
          [stepDescription]="'AUTO_LOT.GUIDE.ADDITIONAL_TAGS.DESCR' | translate"
          [stepSide]="'left'"
          [stepAlign]="'start'"
          [popoverClass]="'auto-lot'"
        >
          <app-lot-icons
            [lot]="data.lot"
            [lotType]="data.lotType"
            [lotSource]="data.lotSource"
            [lotIcons]="data.icons"
            [currentUser]="data.currentUser"
            [changeManagerState]="changeManagerState"
          ></app-lot-icons>
        </section>

        <section
          appDriverStep
          [overrideStepIndex]="3"
          [stepTitle]="'AUTO_LOT.GUIDE.ACTION_BTNS.FIRST.TITLE' | translate"
          [stepDescription]="'AUTO_LOT.GUIDE.ACTION_BTNS.FIRST.DESCR' | translate"
          [stepSide]="'left'"
          [stepAlign]="'start'"
          [popoverClass]="'auto-lot'"
        >
          <div
            appDriverStep
            [overrideStepIndex]="4"
            [stepTitle]="'AUTO_LOT.GUIDE.ACTION_BTNS.SECOND.TITLE' | translate"
            [stepDescription]="'AUTO_LOT.GUIDE.ACTION_BTNS.SECOND.DESCR' | translate"
            [stepSide]="'bottom'"
            [stepAlign]="'start'"
            [popoverClass]="'auto-lot'"
          >
            <div class="buttons">
              <app-lot-place-bid-button
                *ngIf="data.lot"
                [lot]="data.lot"
                [limits]="data.limits"
                [lotType]="data.lotType"
                [lotSource]="data.lotSource"
                [calculatorTypes]="data.calcTypes"
                [hasTranslations]="data.hasTranslations"
                [currentUserLimitsOverrideAvailable]="data.currentUserLimitsOverrideAvailable"
                [currentUserLastBid]="data.currentUserLastBid"
                (afterSubmit)="refetch$.next()"
              ></app-lot-place-bid-button>
              <app-share-button
                [lot]="data.lot"
                [lotType]="data.lotType"
                [lotSource]="data.lotSource"
                [userLotMarks]="data.currentUserLotMarks"
                (changeMark)="refetch$.next()"
              ></app-share-button>
              <app-lot-translate-button
                [lot]="data.lot"
                [lotType]="data.lotType"
                [lotSource]="data.lotSource"
              ></app-lot-translate-button>
              <app-lot-add-photo-button
                [lot]="data.lot"
                [lotType]="data.lotType"
                [lotSource]="data.lotSource"
                [currentUser]="data.currentUser"
                (finish)="refetch$.next()"
              ></app-lot-add-photo-button>
              <app-lot-bids
                *ngIf="data.currentUser.canSeeUsersBids === true"
                [bids]="data.bids"
              ></app-lot-bids>
              <app-lot-events-modal
                [lot]="data.savedLot"
                [currentUser]="data.currentUser"
              ></app-lot-events-modal>
              <app-lot-reset-button
                *ngIf="data.lot.isLotDataChanged"
                [lot]="data.lot"
                [lotType]="data.lotType"
                [lotSource]="data.lotSource"
                [currentUser]="data.currentUser"
                (resetCompleted)="refetch$.next()"
              ></app-lot-reset-button>
              <app-purchase-report-button
                *ngIf="data.currentUser.isAuctioneer"
                [lot]="data.lot"
                [lotType]="data.lotType"
              ></app-purchase-report-button>
            </div>

            <div class="buttons">
              <app-lot-create-translation-request-button
                [currentUser]="data.currentUser"
                [lot]="data.lot"
                [lotType]="data.lotType"
                [lotSource]="data.lotSource"
                [hasTranslations]="data.hasTranslations"
                (translationRequestCreated)="refetch$.next()"
                (clickIfTranslationsExists)="scrollToTranslations()"
              ></app-lot-create-translation-request-button>
              <app-lot-get-photo-request-button
                [lot]="data.lot"
                [lotType]="data.lotType"
                [lotSource]="data.lotSource"
                (sendComplete)="refetch$.next()"
              ></app-lot-get-photo-request-button>
              <button nz-button nzSize="large" (click)="scrollToPreviousSales()">
                {{ 'PREVIOUS_SALES' | translate }}
              </button>
              <app-lot-notations-button></app-lot-notations-button>
            </div>
          </div>
        </section>

        <section nz-row [nzGutter]="[10, 10]">
          <nz-col>
            <app-lot-shared-add-list-button
              [lot]="data.lot"
              [lotType]="data.lotType"
              [lotSource]="data.lotSource"
              [userLotMarks]="data.currentUserLotMarks"
              appDriverStep
              [overrideStepIndex]="5"
              [stepTitle]="'AUTO_LOT.GUIDE.SELECTION.TITLE' | translate"
              [stepDescription]="'AUTO_LOT.GUIDE.SELECTION.DESCR' | translate"
              [stepSide]="'left'"
              [stepAlign]="'start'"
              [popoverClass]="'auto-lot'"
            ></app-lot-shared-add-list-button>
          </nz-col>
          <nz-col>
            <app-lot-check-production-date-input
              [lot]="data.lot"
              [lotType]="data.lotType"
              [lotSource]="data.lotSource"
              [fullVin]="data.fullVin"
              [limits]="data.limits"
              [currentUser]="data.currentUser"
              (inputFocus)="goToFirstSlide(data.isUsingSavedImages)"
            ></app-lot-check-production-date-input>
          </nz-col>
        </section>

        <section>
          <app-lot-shared-includes [lotId]="data.lot?.id"></app-lot-shared-includes>
        </section>

        <section>
          <app-lot-translations
            id="lotTranslations"
            [translations]="data.translations"
          ></app-lot-translations>
        </section>

        <section *ngIf="!data.currentUser.isOtherCountries">
          <app-lot-boundary-info
            [lot]="data.lot"
            [style]="'alert'"
            [excludeStatuses]="[BoundaryStatus.NotBoundary, BoundaryStatus.Early]"
          ></app-lot-boundary-info>
        </section>

        <section>
          <app-lot-main-info
            [lot]="data.lot"
            [lotType]="data.lotType"
            [lotSource]="data.lotSource"
            [bestBid]="data.bestBid"
            [hasBestBid]="data.hasBestBid"
            [currentUser]="data.currentUser"
            [hasTranslations]="data.hasTranslations"
            [currentUserLastBid]="data.currentUserLastBid"
            (actionComplete)="refetch$.next()"
          ></app-lot-main-info>
        </section>

        <section>
          <app-lot-check-production-date-result
            [lot]="data.lot"
          ></app-lot-check-production-date-result>
        </section>

        <section>
          <app-lot-statistics
            id="previousSales"
            [statisticsOpen]="statisticsOpen"
            *ngIf="data.lotType !== LotSource.Mikhail"
            [lot]="data.lot"
            appDriverStep
            [overrideStepIndex]="12"
            [stepTitle]="'AUTO_LOT.GUIDE.SELL_STATS.TITLE' | translate"
            [stepDescription]="'AUTO_LOT.GUIDE.SELL_STATS.DESCR' | translate"
            [stepSide]="'left'"
            [stepAlign]="'start'"
            [popoverClass]="'auto-lot'"
          ></app-lot-statistics>
          <app-lot-extra-info [lot]="data.lot" [active]="true"></app-lot-extra-info>
          <app-lot-ticket-rules [lot]="data.lot"></app-lot-ticket-rules>
        </section>

        <section *ngIf="data.calcTypes.length">
          <lib-akebono-calculator
            [lot]="data.calcData"
            [types]="data.calcTypes"
            appDriverStep
            [overrideStepIndex]="14"
            [stepTitle]="'AUTO_LOT.GUIDE.CALC.TITLE' | translate"
            [stepDescription]="'AUTO_LOT.GUIDE.CALC.DESCR' | translate"
            [stepSide]="'left'"
            [stepAlign]="'start'"
            [popoverClass]="'auto-lot'"
          ></lib-akebono-calculator>
        </section>
      </aside>
    </main>
  </ng-container>
</nz-spin>
