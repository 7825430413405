import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from, iif, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, toArray } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import {
  LotStatusEnum,
  LotVersionsFragment,
  TrailEvent,
  UserLotQuery,
} from '../../graphql/service/graphql-auto-main-service';

type Version = TrailEvent & {
  changeset$: Observable<KeyValue<string, any>[]>;
};

@Component({
  selector: 'app-lot-events-modal',
  templateUrl: './lot-events-modal.component.html',
  styleUrls: ['./lot-events-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotEventsModalComponent {
  versions$: Observable<Version[]> = of([]);

  rawView = false;
  isVisible = false;

  // order is messed up in generated enum
  lotStatuses = [
    LotStatusEnum.Initial,
    LotStatusEnum.Sold,
    LotStatusEnum.Negotiations,
    LotStatusEnum.Won,
    LotStatusEnum.Canceled,
    LotStatusEnum.Unsold,
    LotStatusEnum.NegotiationSold,
    LotStatusEnum.Removed,
  ];
  managerDomain = environment.managerDomain;

  excludeFields = ['production_date_payload'];

  @HostBinding('style.display') display: 'none' | 'inline-block' = 'none';

  constructor(private translate: TranslateService) {}

  @Input()
  set currentUser(currentUser: UserLotQuery['currentUser']) {
    this.display = currentUser.isAuctioneer ? 'inline-block' : 'none';
  }

  @Input()
  set lot(lot: LotVersionsFragment) {
    console.log(lot);
    this.versions$ = from(lot?.versions ?? []).pipe(
      map(
        (version) =>
          ({
            ...version,
            changeset$: of(version.changeset).pipe(
              switchMap((changeset) => from(Object.entries(changeset))),
              map(([key, value]) => ({ key, value })),
              filter(({ key }) => !this.excludeFields.includes(key)),
              toArray<KeyValue<string, any>>(),
              switchMap((changes: KeyValue<string, any>[]) =>
                iif(
                  () => changes.length === 1 && changes[0].key === 'updated_at',
                  of(changes),
                  from(changes).pipe(
                    filter(({ key }) => key !== 'updated_at'),
                    toArray(),
                  ),
                ),
              ),
              shareReplay(1),
            ),
          } as Version),
      ),
      toArray(),
      shareReplay(1),
    );
  }

  extractIcons(iconChangeStr: string): string {
    return iconChangeStr
      .replace(/\{|\}/g, '')
      .split(',')
      .filter((icon) => Boolean(icon))
      .map((icon) => this.translate.instant(`LOT_TRANSLATION.ICONS_TOOLTIP.${icon}`))
      .join(', ');
  }

  extractImages(imagesChangeStr: string): string[] {
    return JSON.parse(imagesChangeStr);
  }
}
