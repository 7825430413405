import { MutationHandlingServiceV2 } from '@akebono/core';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutoLotView } from 'src/app/const';

import { LotResetGQL, LotSource, LotTypeEnum, UserLotQuery } from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-reset-button',
  templateUrl: './lot-reset-button.component.html',
  styleUrls: ['./lot-reset-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotResetButtonComponent {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Output() resetCompleted = new EventEmitter<void>();

  @HostBinding('style.display') display: 'none' | 'inline-block' = 'none';

  loading$ = of(false);

  @Input()
  set currentUser(currentUser: UserLotQuery['currentUser']) {
    this.display = currentUser.isAuctioneer ? 'inline-block' : 'none';
  }

  constructor(
    private translate: TranslateService,
    private modalService: NzModalService,
    private mhs: MutationHandlingServiceV2,
    private lotResetGQL: LotResetGQL,
  ) {}

  resetLot(): void {
    this.modalService.confirm({
      nzTitle: this.translate.instant('RESET_LOT.TITLE'),
      nzContent: this.translate.instant('RESET_LOT.WARNING'),
      nzOnOk: () =>
        this.mhs
          .mutate(this.lotResetGQL, {
            input: {
              lotId: this.lot.id,
              lotType: this.lotType,
              lotSource: this.lotSource,
            },
          })
          .data.pipe(finalize(() => this.resetCompleted.emit()))
          .toPromise(),
    });
  }
}
