import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { defer, iif, merge, Observable, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AutoLotView } from '../../../../../const';
import {
  LotSource,
  LotTypeEnum,
  UserLotMark,
  UserLotMarkEnum,
} from '../../../../graphql/service/graphql-auto-main-service';
import { AutoLot } from '../../../../graphql/service/graphql-cars-default-service';
import { LotInterestingManager } from '../../services/lot-interesting-manager/lot-interesting-manager';
import { LotInterestingManagerFactory } from '../../services/lot-interesting-manager/lot-interesting-manager-factory.service';

@Component({
  selector: 'app-lot-interesting-status',
  templateUrl: './lot-interesting-status.component.html',
  styleUrls: ['./lot-interesting-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotInterestingStatusComponent implements OnInit {
  @Input() lot: AutoLot | AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() manualLoadMarks = false;
  @HostBinding('style.display') display: 'none' | 'block' = 'none';

  interesting$: Observable<UserLotMarkEnum | null> = of(null);

  private interestingManager: LotInterestingManager;
  private lotMarks$ = new Subject<UserLotMark[]>();

  UserLotMarkEnum = UserLotMarkEnum;

  @Input()
  set marks(marks: UserLotMark[]) {
    this.lotMarks$.next(marks ?? []);
  }

  constructor(private interestingManagerFactory: LotInterestingManagerFactory) {}

  ngOnInit(): void {
    this.interestingManager = this.interestingManagerFactory.createAll(
      this.lot,
      this.lotType,
      this.lotSource,
    );

    this.interesting$ = merge(
      this.interestingManager.changes$,
      iif(
        () => this.manualLoadMarks,
        defer(() =>
          this.lotMarks$.pipe(switchMap((marks) => this.interestingManager.isInteresting(marks))),
        ),
        defer(() => this.interestingManager.isInteresting()),
      ),
    ).pipe(tap((interesting) => (this.display = interesting !== null ? 'block' : 'none')));
  }
}
